import {
  axios_delete,
  axios_get,
  axios_post,
  axios_put,
} from "../services/apiservice";
import { getCurrentUser } from "../shared/user";
import {
  admCommentsRecognitionApiUrl,
  admConsultationApiUrl,
  admEventApiUrl,
  admKonselorApiUrl,
  admMasterKonselorApiUrl,
  admMitraApiUrl,
  admOvGrafikRecognitionApiUrl,
  admOvGrafikTracerApiUrl,
  admOvRekapRecognitionApiUrl,
  admPlotingKonselorApiUrl,
  admRecognitionApiUrl,
  admRekapKuesTracerApiUrl,
  admRekapWisudaTracerApiUrl,
  admRootRoute,
  admSearchKonselorApiUrl,
  admSurveiPenggunaApiUrl,
  admin,
  alumni,
  applyCareerApiUrl,
  chatKonsultasiApiUrl,
  checkFilledTracerUrl,
  checkLikeRecognitionApiUrl,
  checkSaveRecognitionApiUrl,
  dataAlumniApiUrl,
  dataTracerUrl,
  dislikedRecognitionApiUrl,
  documentApiUrl,
  eventApiUrl,
  filterCareerApiUrl,
  fullNameApiUrl,
  grafikAktifCariKerjaOvTracerApiUrl,
  grafikAktifCariKerjaOvTracerApiUrlV1,
  grafikBedaKerjaOvTracerApiUrl,
  grafikBedaKerjaOvTracerApiUrlV1,
  grafikCaraCariKerjaOvTracerApiUrl,
  grafikCaraCariKerjaOvTracerApiUrl2,
  grafikCariKerjaOvTracerApiUrl,
  grafikCariKerjaOvTracerApiUrlV1,
  grafikDapatKerjaOvTracerApiUrl,
  grafikHubStudiKerjaOvTracerApiUrl,
  grafikJenisPerusahaanOvTracerApiUrl,
  grafikPosisiWiraOvTracerApiUrl,
  grafikProCariKerjaOvTracerApiUrl,
  grafikProCariKerjaOvTracerApiUrlV1,
  grafikStatusOvTracerApiUrl,
  grafikSumberBiayaOvTracerApiUrl,
  grafikTingkatPendOvTracerApiUrl,
  grafikTingkatWiraOvTracerApiUrl,
  historyConsultationApiUrl,
  identifyUserKey,
  jobsCareerApiUrl,
  jobsCareerNoMitApiUrl,
  konRootRoute,
  konselor,
  landingRoute,
  likedRecognitionApiUrl,
  loginApiUrl,
  logoutApiUrl,
  masterFilterOvTracerApiUrl,
  masterPosisiTracer,
  masterProdiApiUrl,
  masterProvinsiTracer,
  masterSumBiayaTracer,
  masterTingKejaTracer,
  mitApplyLowonganApiUrl,
  mitApproveLowonganApiUrl,
  mitLowonganApiUrl,
  mitRootRoute,
  mitra,
  notificationsApiUrl,
  plottingConsultationApiUrl,
  proOvGrafikRecognitionApiUrl,
  proOvGrafikTracerApiUrl,
  proRecognitionApiUrl,
  proRekapKuesTracerApiUrl,
  proRekapWisudaTracerApiUrl,
  proRootRoute,
  prodi,
  rataDapatKerjaOvTracerApiUrl,
  rataPendapatanOvTracerApiUrl,
  recognitionApiUrl,
  registerMitraApiUrl,
  reqConsultationApiUrl,
  saveRecognitionApiUrl,
  saveTracerUrl,
  savedRecognitionApiUrl,
  sertifAkreditasiApiUrl,
  tableMetodeBelajarOvTracerApiUrl,
  tableMetodeBelajarOvTracerApiUrlV1,
  tableSaranTracerStudiApiUrl,
  tableTingkatKomKerjaOvTracerApiUrl,
  tableTingkatKomKerjaOvTracerApiUrlV1,
  tableTingkatKomOvTracerApiUrl,
  tableTingkatKomOvTracerApiUrlV1,
  tagUserRecognitionApiUrl,
  taggedRecognitionApiUrl,
  tokenApiKey,
  unsaveRecognitionApiUrl,
  upImgRecognitionApiUrl,
} from "../shared/variable";

//! GLOBAL API CONTROLLER ******************************************************************************************************
//?
export const searchUserLoginController = async ({ query, identify }) => {
  const header = {};
  const params = {
    search: query,
    kode: identify,
  };
  const result = await axios_get(fullNameApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? LOGIN API REQUEST ===============================================================================
export const postLoginAccount = async (username, password) => {
  const body = {
    username: username,
    password: password,
  };
  const result = await axios_post(loginApiUrl, body);
  if (result.status) {
    const identifyUser = result.kode;
    const tokenApi = result.token;
    localStorage.setItem(identifyUserKey, JSON.stringify(identifyUser));
    localStorage.setItem(tokenApiKey, JSON.stringify(tokenApi));

    let page = landingRoute;
    if (JSON.stringify(identifyUser) === admin) {
      page = admRootRoute;
    } else if (JSON.stringify(identifyUser) === prodi) {
      page = proRootRoute;
    } else if (JSON.stringify(identifyUser) === alumni) {
      page = landingRoute;
    } else if (JSON.stringify(identifyUser) === konselor) {
      page = konRootRoute;
    } else if (JSON.stringify(identifyUser) === mitra) {
      page = mitRootRoute;
    }
    return {
      status: true,
      page: page,
    };
  }
  return result;
};

//? LOGOUT API REQUEST ===============================================================================
export const postLogoutAccount = async () => {
  const result = await axios_post(logoutApiUrl);
  if (result.status) {
    return true;
  }
  return false;
};

//? GET FULL NAME OF USER ================================================================================
export const getFullNameUser = async (identify, iduser) => {
  const result = await axios_get(`${fullNameApiUrl}/${identify}/${iduser}`);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? SAVE NEW RECOGNITION API REQUEST ================================================================
export const saveNewRecognition = async (
  title,
  images,
  tags,
  content,
  rasio,
  view
) => {
  const body = {
    title: title,
    images: images,
    tags: tags,
    body: content,
    rasio: rasio,
    view: view,
  };
  const result = await axios_post(recognitionApiUrl, body, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET OVERVIEW RECOGNITION API REQUEST ===================================================================
export const getDataOverviewRecognition = async (action = 10) => {
  const result = await axios_get(
    action === 10 ? admRecognitionApiUrl : proRecognitionApiUrl
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET REKAP OVERVIEW RECOGNITION API REQUEST ===================================================================
export const getDataGrafikOverviewRecognition = async (action = 10) => {
  const result = await axios_get(
    action === 10 ? admOvGrafikRecognitionApiUrl : proOvGrafikRecognitionApiUrl
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET APPROVE DATA RECOGNITION API REQUEST ===============================================================
export const getApproveDataRecognition = async ({
  action = 10,
  search = null,
  page = 1,
  limit = 10,
  order = null,
  asc = null,
}) => {
  const header = {};
  const body = {
    approve: "ACCEPTED",
  };
  const params = {
    search,
    page,
    limit,
    order,
    asc,
  };
  const result = await axios_post(
    action === 10 ? admRecognitionApiUrl : proRecognitionApiUrl,
    body,
    true,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET WAITING DATA RECOGNITION API REQUEST ===============================================================
export const getWaitingApproveDataRecognition = async ({
  action = 10,
  search = null,
  page = 1,
  limit = 10,
  order = null,
  asc = null,
}) => {
  const header = {};
  const body = {
    approve: "PROCESSED",
  };
  const params = {
    search,
    page,
    limit,
    order,
    asc,
  };
  const result = await axios_post(
    action === 10 ? admRecognitionApiUrl : proRecognitionApiUrl,
    body,
    true,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET REJECTED DATA RECOGNITION API REQUEST ===============================================================
export const getRejectDataRecognition = async ({
  action = 10,
  search = null,
  page = 1,
  limit = 10,
  order = null,
  asc = null,
}) => {
  const header = {};
  const body = {
    approve: "REJECTED",
  };
  const params = {
    search,
    page,
    limit,
    order,
    asc,
  };
  const result = await axios_post(
    action === 10 ? admRecognitionApiUrl : proRecognitionApiUrl,
    body,
    true,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const getDataConsultation = async ({
  search = null,
  page = 1,
  limit = 10,
  order = null,
  asc = null,
  approve = null,
  status = null,
}) => {
  const header = {};
  const params = {
    search,
    page,
    limit,
    order,
    asc,
    approve,
    status,
  };
  const result = await axios_get(admConsultationApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const getDataKonselorConsultation = async ({ id }) => {
  const header = {};
  const params = {
    alumni: id,
  };
  const result = await axios_get(admKonselorApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const plotingKonselorConsultation = async ({
  idkonsul,
  idkonselor,
  approve,
}) => {
  const body = {
    id_konsultasi: idkonsul,
    id_konselor: idkonselor,
    approve: approve,
  };
  const result = await axios_post(admPlotingKonselorApiUrl, body, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET GRAFIK OVERVIEW TRACER STUDY API REQUEST ===================================================================
export const getDataGrafikOverviewTracer = async ({
  action = 10,
  tahun = null,
}) => {
  const header = {};
  const params = {
    tahun: tahun,
  };
  const result = await axios_get(
    action === 10 ? admOvGrafikTracerApiUrl : proOvGrafikTracerApiUrl,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET GRAFIK STATUS OVERVIEW TRACER STUDY API REQUEST ===================================================================
export const getDataGrafikStatusOvTracer = async ({
  tahun = null,
  prodi = null,
}) => {
  const header = {};
  const params = {
    tahun: tahun,
    prodi: prodi,
  };
  const result = await axios_get(grafikStatusOvTracerApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET RATA2 PENDAPATAN OVERVIEW TRACER STUDY API REQUEST ===================================================================
export const getDataRataPendapatanOvTracer = async ({
  tahun = null,
  prodi = null,
}) => {
  const header = {};
  const params = {
    tahun: tahun,
    prodi: prodi,
  };
  const result = await axios_get(rataPendapatanOvTracerApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET GRAFIK DAPAT KERJA OVERVIEW TRACER STUDY API REQUEST ===================================================================
export const getDataDapatKerjaOvTracer = async ({
  tahun = null,
  prodi = null,
}) => {
  const header = {};
  const params = {
    tahun: tahun,
    prodi: prodi,
  };
  const result = await axios_get(
    grafikDapatKerjaOvTracerApiUrl,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET RATA2 DAPAT KERJA OVERVIEW TRACER STUDY API REQUEST ===================================================================
export const getDataRataDapatKerjaOvTracer = async ({
  tahun = null,
  prodi = null,
}) => {
  const header = {};
  const params = {
    tahun: tahun,
    prodi: prodi,
  };
  const result = await axios_get(rataDapatKerjaOvTracerApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET GRAFIK DAPAT HUBUNGAN STUDI KERJA OVERVIEW TRACER STUDY API REQUEST ===================================================================
export const getDataHubStudiKerjaOvTracer = async ({
  tahun = null,
  prodi = null,
}) => {
  const header = {};
  const params = {
    tahun: tahun,
    prodi: prodi,
  };
  const result = await axios_get(
    grafikHubStudiKerjaOvTracerApiUrl,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET GRAFIK DAPAT JENIS PERUSAHAAN OVERVIEW TRACER STUDY API REQUEST ===================================================================
export const getDataJenisPerusahaanOvTracer = async ({
  tahun = null,
  prodi = null,
}) => {
  const header = {};
  const params = {
    tahun: tahun,
    prodi: prodi,
  };
  const result = await axios_get(
    grafikJenisPerusahaanOvTracerApiUrl,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET GRAFIK DAPAT POSISI WIRAUSAHA OVERVIEW TRACER STUDY API REQUEST ===================================================================
export const getDataPosisiWiraOvTracer = async ({
  tahun = null,
  prodi = null,
}) => {
  const header = {};
  const params = {
    tahun: tahun,
    prodi: prodi,
  };
  const result = await axios_get(
    grafikPosisiWiraOvTracerApiUrl,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET GRAFIK DAPAT TINGKAT WIRAUSAHA OVERVIEW TRACER STUDY API REQUEST ===================================================================
export const getDataTingkatWiraOvTracer = async ({
  tahun = null,
  prodi = null,
}) => {
  const header = {};
  const params = {
    tahun: tahun,
    prodi: prodi,
  };
  const result = await axios_get(
    grafikTingkatWiraOvTracerApiUrl,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET GRAFIK DAPAT TINGKAT PENDIDIKAN OVERVIEW TRACER STUDY API REQUEST ===================================================================
export const getDataTingkatPendOvTracer = async ({
  tahun = null,
  prodi = null,
}) => {
  const header = {};
  const params = {
    tahun: tahun,
    prodi: prodi,
  };
  const result = await axios_get(
    grafikTingkatPendOvTracerApiUrl,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET GRAFIK DAPAT JENIS PERUSAHAAN OVERVIEW TRACER STUDY API REQUEST ===================================================================
export const getDataSumberBiayaKuliahOvTracer = async ({
  tahun = null,
  prodi = null,
}) => {
  const header = {};
  const params = {
    tahun: tahun,
    prodi: prodi,
  };
  const result = await axios_get(
    grafikSumberBiayaOvTracerApiUrl,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET DATA TABEL TINGKAT KOMPETENSI OVERVIEW TRACER STUDY API REQUEST ===================================================================
export const getDataTingkatKompetensiOvTracerV1 = async ({
  tahun = null,
  prodi = null,
  order = null,
  asc = null,
}) => {
  const header = {};
  const params = {
    tahun: tahun,
    prodi: prodi,
    order,
    asc,
  };
  const result = await axios_get(
    tableTingkatKomOvTracerApiUrlV1,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};
export const getDataTingkatKompetensiOvTracer = async ({
  date_start = null,
  date_end = null,
  prodi = null,
  order = null,
  asc = null,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
    order,
    asc,
  };
  const result = await axios_get(tableTingkatKomOvTracerApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET DATA TABEL TINGKAT KOMPETENSI PEKERJAAN OVERVIEW TRACER STUDY API REQUEST ===================================================================
export const getDataTingkatKomKerjaOvTracerV1 = async ({
  tahun = null,
  prodi = null,
  order = null,
  asc = null,
}) => {
  const header = {};
  const params = {
    tahun: tahun,
    prodi: prodi,
    order,
    asc,
  };
  const result = await axios_get(
    tableTingkatKomKerjaOvTracerApiUrlV1,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};
export const getDataTingkatKomKerjaOvTracer = async ({
  date_start = null,
  date_end = null,
  prodi = null,
  order = null,
  asc = null,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
    order,
    asc,
  };
  const result = await axios_get(
    tableTingkatKomKerjaOvTracerApiUrl,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET DATA TABEL METODE PEMBELAJARAN OVERVIEW TRACER STUDY API REQUEST ===================================================================
export const getDataMetodeBelajarOvTracerV1 = async ({
  tahun = null,
  prodi = null,
  order = null,
  asc = null,
}) => {
  const header = {};
  const params = {
    tahun: tahun,
    prodi,
    order,
    asc,
  };
  const result = await axios_get(
    tableMetodeBelajarOvTracerApiUrlV1,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};
export const getDataMetodeBelajarOvTracer = async ({
  date_start = null,
  date_end = null,
  prodi = null,
  order = null,
  asc = null,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi,
    order,
    asc,
  };
  const result = await axios_get(
    tableMetodeBelajarOvTracerApiUrl,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET GRAFIK DAPAT KERJA OVERVIEW TRACER STUDY API REQUEST ===================================================================
export const getDataCariKerjaOvTracerV1 = async ({
  tahun = null,
  prodi = null,
}) => {
  const header = {};
  const params = {
    tahun: tahun,
    prodi: prodi,
  };
  const result = await axios_get(
    grafikCariKerjaOvTracerApiUrlV1,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};
export const getDataCariKerjaOvTracer = async ({
  date_start = null,
  date_end = null,
  prodi = null,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  const result = await axios_get(grafikCariKerjaOvTracerApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET GRAFIK DAPAT KERJA OVERVIEW TRACER STUDY API REQUEST ===================================================================
export const getDataAktifCariKerjaOvTracerV1 = async ({
  tahun = null,
  prodi = null,
}) => {
  const header = {};
  const params = {
    tahun: tahun,
    prodi: prodi,
  };
  const result = await axios_get(
    grafikAktifCariKerjaOvTracerApiUrlV1,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};
export const getDataAktifCariKerjaOvTracer = async ({
  date_start = null,
  date_end = null,
  prodi = null,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
  };
  const result = await axios_get(
    grafikAktifCariKerjaOvTracerApiUrl,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET GRAFIK DAPAT KERJA OVERVIEW TRACER STUDY API REQUEST ===================================================================

export const getDataCaraCariKerjaOvTracer = async ({
  tahun = null,

  prodi = null,
}) => {
  const header = {};
  const params = {
    tahun: tahun,

    prodi: prodi,
  };
  const result = await axios_get(
    grafikCaraCariKerjaOvTracerApiUrl,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET GRAFIK DAPAT KERJA OVERVIEW TRACER STUDY API REQUEST ===================================================================
export const getDataCaraCariKerjaOvTracerV1 = async ({
  tahun = null,
  prodi = null,
  order = null,
  asc = null,
}) => {
  const header = {};
  const params = {
    tahun: tahun,
    prodi: prodi,
    order,
    asc,
  };
  const result = await axios_get(
    grafikCaraCariKerjaOvTracerApiUrl2,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};
export const getDataCaraCariKerjaOvTracer2 = async ({
  date_start = null,
  date_end = null,
  prodi = null,
  order = null,
  asc = null,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
    order,
    asc,
  };
  const result = await axios_get(
    grafikCaraCariKerjaOvTracerApiUrl2,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET DATA TABEL METODE PEMBELAJARAN OVERVIEW TRACER STUDY API REQUEST ===================================================================
export const getDataProCariKerjaOvTracerV1 = async ({
  tahun = null,
  prodi = null,
  order = null,
  asc = null,
}) => {
  const header = {};
  const params = {
    tahun: tahun,
    prodi: prodi,
    order,
    asc,
  };
  const result = await axios_get(
    grafikProCariKerjaOvTracerApiUrlV1,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const getDataProCariKerjaOvTracerV2 = async ({
  date_start = null,
  date_end = null,
  prodi = null,
  order = null,
  asc = null,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
    order,
    asc,
  };
  const result = await axios_get(
    grafikProCariKerjaOvTracerApiUrl,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET GRAFIK DAPAT KERJA OVERVIEW TRACER STUDY API REQUEST ===================================================================
export const getDataBedaPekerjaanOvTracerV1 = async ({
  tahun = null,
  prodi = null,
  order = null,
  asc = null,
}) => {
  const header = {};
  const params = {
    tahun: tahun,
    prodi: prodi,
    order,
    asc,
  };
  const result = await axios_get(
    grafikBedaKerjaOvTracerApiUrlV1,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const getDataBedaPekerjaanOvTracer = async ({
  date_start = null,
  date_end = null,
  prodi = null,
  order = null,
  asc = null,
}) => {
  const header = {};
  const params = {
    date_start: date_start,
    date_end: date_end,
    prodi: prodi,
    order,
    asc,
  };
  const result = await axios_get(grafikBedaKerjaOvTracerApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET REKAP KUESIONER TRACER STUDY API REQUEST ===================================================================
export const getRekapKuesionerTracerController = async ({
  action = 10,
  search = null,
  page = 1,
  limit = 10,
  order = null,
  asc = null,
  start = null,
  end = null,
}) => {
  const header = {};
  const body = {
    date_start: start,
    date_end: end,
  };
  const params = {
    search,
    page,
    limit,
    order,
    asc,
  };
  const result = await axios_post(
    action === 10 ? admRekapKuesTracerApiUrl : proRekapKuesTracerApiUrl,
    body,
    true,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET REKAP WISUDA TRACER STUDY API REQUEST ===================================================================
export const getRekapWisudaTracerController = async ({
  action = 10,
  year = null,
  month = null,
}) => {
  const body = {
    tahun: year,
    bulan: month,
  };
  const result = await axios_post(
    action === 10 ? admRekapWisudaTracerApiUrl : proRekapWisudaTracerApiUrl,
    body,
    true
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET REKAP WISUDA TRACER STUDY API REQUEST ===================================================================
export const getRekapSurveiPenggunaController = async ({
  action = 10,
  search = null,
  page = 1,
  limit = 10,
  order = null,
  asc = null,
  date_start = null,
  date_end = null,
  mhs_prodi = null,
}) => {
  const header = {};
  const params = {
    search,
    page,
    limit,
    order,
    asc,
    date_start,
    date_end,
    mhs_prodi,
  };
  const result = await axios_get(
    action === 10 ? admSurveiPenggunaApiUrl : proRekapWisudaTracerApiUrl,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET REKAP WISUDA TRACER STUDY API REQUEST ===================================================================
export const getSaranTracerStudiController = async ({
  search = null,
  page = 1,
  limit = 10,
  order = null,
  asc = null,
  tahun = null,
  prodi = null,
}) => {
  const header = {};
  const params = {
    search,
    page,
    limit,
    order,
    asc,
    tahun,
    prodi,
  };
  const result = await axios_get(tableSaranTracerStudiApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET MASTER FILTER OVERVIEW TRACER STUDY API REQUEST ===================================================================
export const getDataMasterFilterOvTracer = async () => {
  const result = await axios_get(masterFilterOvTracerApiUrl);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET MASTER FILTER OVERVIEW TRACER STUDY API REQUEST ===================================================================
export const getChatKonsultasi = async (id) => {
  const result = await axios_get(`${chatKonsultasiApiUrl}/${id}`);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET MASTER FILTER OVERVIEW TRACER STUDY API REQUEST ===================================================================
export const sendChatKonsultasi = async (id, message, idreply = null) => {
  const body =
    idreply !== null
      ? {
          id_konsultasi: id,
          chat: message,
          replay_to: idreply,
        }
      : {
          id_konsultasi: id,
          chat: message,
        };
  const result = await axios_post(`${chatKonsultasiApiUrl}/${id}`, body, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET MASTER FILTER OVERVIEW TRACER STUDY API REQUEST ===================================================================
export const deleteChatKonsultasi = async (id) => {
  const result = await axios_delete({
    url: `${chatKonsultasiApiUrl}/${id}`,
  });
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET MASTER FILTER OVERVIEW TRACER STUDY API REQUEST ===================================================================
export const finishKonsultasi = async (id) => {
  const body = {};
  const result = await axios_post(
    `${chatKonsultasiApiUrl}/${id}/finish`,
    body,
    true
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET MASTER FILTER OVERVIEW TRACER STUDY API REQUEST ===================================================================
export const rateKonsultasi = async (id, rate) => {
  const body = {
    rating: rate,
  };
  const result = await axios_post(
    `${chatKonsultasiApiUrl}/${id}/rate`,
    body,
    true
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET MASTER FILTER OVERVIEW TRACER STUDY API REQUEST ===================================================================
export const expiredKonsultasi = async (id) => {
  const body = {};
  const result = await axios_post(
    `${chatKonsultasiApiUrl}/${id}/expired`,
    body,
    true
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const getAllNotifications = async () => {
  const result = await axios_get(notificationsApiUrl);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const getCustomNotifications = async (action) => {
  const header = {};
  const params = {
    status: action === 10 ? "UNREAD" : "READ",
  };
  const result = await axios_get(notificationsApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const updateReadNotifications = async (id) => {
  const body = {
    id_notifikasi: id,
    is_read: true,
  };
  const result = await axios_post(notificationsApiUrl, body, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const getMasterProdi = async () => {
  const result = await axios_get(masterProdiApiUrl);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const getCertificate = async ({
  action = 10,
  page = 1,
  limit = 10,
  search = null,
}) => {
  const header = {};
  const params = {
    page,
    limit,
    search,
  };
  const result = await axios_get(
    action === 10 ? sertifAkreditasiApiUrl : `${sertifAkreditasiApiUrl}/univ`,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const saveCertificate = async (action, prodi, tahun, file) => {
  const bodyFromData = new FormData();
  bodyFromData.append("kode_prodi", prodi);
  bodyFromData.append("tahun", tahun);
  bodyFromData.append("file", file);
  const result = await axios_post(
    action === 10 ? sertifAkreditasiApiUrl : `${sertifAkreditasiApiUrl}/univ`,
    bodyFromData,
    true
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const deleteCertificate = async (action, id, tahun) => {
  const result = await axios_delete({
    url:
      action === 10
        ? `${sertifAkreditasiApiUrl}/${id}/${tahun}`
        : `${sertifAkreditasiApiUrl}/${tahun}`,
  });
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const getFilterCareerController = async () => {
  const result = await axios_get(filterCareerApiUrl);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const getAllJobsController = async ({
  jenispekerjaan = null,
  waktu = null,
  prov = 1,
  kota = 10,
  posisi = null,
}) => {
  const header = {};
  const params = {
    jenispekerjaan,
    waktu,
    prov,
    kota,
    posisi,
  };
  const result = await axios_get(jobsCareerApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//! ADMIN API CONTROLLER ******************************************************************************************************
//? GET REKAP OVERVIEW RECOGNITION API REQUEST ===================================================================
export const getDataRekapOverviewRecognition = async () => {
  const result = await axios_get(admOvRekapRecognitionApiUrl);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? APPROVE RECOGNITION API REQUEST ==================================================================
export const approveRecognition = async (idrec) => {
  const body = {
    approve: "ACCEPTED",
  };
  const result = await axios_post(
    `${admRecognitionApiUrl}/${idrec}/approve`,
    body,
    true
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? RECJECT RECOGNITION API REQUEST ==================================================================
export const rejectRecognition = async (idrec) => {
  const body = {
    approve: "REJECTED",
  };
  const result = await axios_post(
    `${admRecognitionApiUrl}/${idrec}/approve`,
    body,
    true
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? UNDO RECJECT RECOGNITION API REQUEST ==================================================================
export const undoRejectRecognition = async (idrec) => {
  const body = {
    approve: "PROCESSED",
  };
  const result = await axios_post(
    `${admRecognitionApiUrl}/${idrec}/approve`,
    body,
    true
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? UPLOAD IMAGE RECOGNITION API REQUEST =============================================================
export const searchUserRecognition = async (query) => {
  const header = {};
  const params = {
    search: query,
  };
  const result = await axios_get(tagUserRecognitionApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? UPLOAD IMAGE RECOGNITION API REQUEST =============================================================
export const uploadImgRecognition = async (img) => {
  const bodyFromData = new FormData();
  bodyFromData.append("image", img);
  const result = await axios_post(upImgRecognitionApiUrl, bodyFromData, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? DELETE IMAGE RECOGNITION API REQUEST =============================================================
export const deleteImgRecognition = async (id) => {
  const result = await axios_delete({
    url: `${upImgRecognitionApiUrl}/${id}`,
  });
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET WAITING DATA COMMENTS RECOGNITION API REQUEST ===============================================================
export const getWaitingApproveDataCommentsRecognition = async ({
  search = null,
  page = 1,
  limit = 10,
  order = null,
  asc = null,
}) => {
  const header = {};
  const params = {
    approve: "PROCESSED",
    search,
    page,
    limit,
    order,
    asc,
  };
  const result = await axios_get(admCommentsRecognitionApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? APPROVE COMMENT RECOGNITION API REQUEST ==================================================================
export const approveCommentRecognition = async (idcom) => {
  const body = {
    id_comment: idcom,
    approve: "ACCEPTED",
  };
  const result = await axios_post(admCommentsRecognitionApiUrl, body, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? RECJECT COMMENT RECOGNITION API REQUEST ==================================================================
export const rejectCommentRecognition = async (idcom) => {
  const body = {
    id_comment: idcom,
    approve: "REJECTED",
  };
  const result = await axios_post(admCommentsRecognitionApiUrl, body, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const processCommentRecognition = async (idcom) => {
  const body = {
    id_comment: idcom,
    approve: "PROCESSED",
  };
  const result = await axios_post(admCommentsRecognitionApiUrl, body, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET REJECTED DATA COMMENTS RECOGNITION API REQUEST ===============================================================
export const getRejectedDataCommentsRecognition = async ({
  search = null,
  page = 1,
  limit = 10,
  order = null,
  asc = null,
}) => {
  const header = {};
  const params = {
    approve: "REJECTED",
    search,
    page,
    limit,
    order,
    asc,
  };
  const result = await axios_get(admCommentsRecognitionApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? UNDO RECJECT COMMENT RECOGNITION API REQUEST ==================================================================
export const undoRejectCommentRecognition = async (idcom) => {
  const body = {
    id_comment: idcom,
    approve: "PROCESSED",
  };
  const result = await axios_post(admCommentsRecognitionApiUrl, body, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const getMasterKonselor = async ({
  search = null,
  page = 1,
  limit = 10,
  order = null,
  asc = null,
}) => {
  const header = {};
  const params = {
    search,
    page,
    limit,
    order,
    asc,
  };
  const result = await axios_get(admMasterKonselorApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const saveNewKonselor = async (listNik) => {
  const body = {
    nik: listNik,
  };
  const result = await axios_post(admMasterKonselorApiUrl, body, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const searchKonselor = async (query) => {
  const header = {};
  const params = {
    s: query,
  };
  const result = await axios_get(admSearchKonselorApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//! PRODI API CONTROLLER *******************************************************************************************************
//? DELETE RECOGNITION API REQUEST ==================================================================
export const deleteRecognition = async (id) => {
  const result = await axios_delete({
    url: `${recognitionApiUrl}/${id}`,
  });
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//! ALUMNI API CONTROLLER ******************************************************************************************************
//? GET DATA ALUMNI API REQUEST =====================================================================
export const getDataAlumni = async () => {
  const { username } = await getCurrentUser();
  const result = await axios_get(`${dataAlumniApiUrl}/${username}`);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET ALL DATA RECOGNITION API REQUEST ===============================================================
export const getDataRecognition = async ({ page = 1, limit = 2 }) => {
  const header = {};
  const params = {
    page,
    limit,
  };
  const result = await axios_get(recognitionApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET SINGLE DATA RECOGNITION API REQUEST ===============================================================
export const getSingleDataRecognition = async (idrec) => {
  const result = await axios_get(`${recognitionApiUrl}/${idrec}`);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET DATA COMMENTS SINGLE RECOGNITION ===================================================================
export const getCommentsSingleRecognition = async (idrec) => {
  const result = await axios_get(`${recognitionApiUrl}/${idrec}/comment`);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? DELETE DATA COMMENTS SINGLE RECOGNITION ===================================================================
export const deleteCommentsRecognition = async (idrec) => {
  const result = await axios_delete(`${recognitionApiUrl}/${idrec}/comment`);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? CHECK USER IS LIKED RECOGNITION OR NOT ===============================================================
export const checkUserLikedRecognition = async (idrec) => {
  const body = {
    id_rekognisi: idrec,
  };
  const result = await axios_post(checkLikeRecognitionApiUrl, body, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? UPDATE USER LIKED RECOGNITION ========================================================================
export const setUserLikedRecognition = async (idrec) => {
  const body = {
    id_rekognisi: idrec,
  };
  const result = await axios_post(likedRecognitionApiUrl, body, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? UPDATE USER DISLIKED RECOGNITION ========================================================================
export const setUserDislikedRecognition = async (idrec) => {
  const body = {
    id_rekognisi: idrec,
  };
  const result = await axios_post(dislikedRecognitionApiUrl, body, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? CHECK USER IS SAVED RECOGNITION OR NOT ===============================================================
export const checkUserSavedRecognition = async (idrec) => {
  const body = {
    id_rekognisi: idrec,
  };
  const result = await axios_post(checkSaveRecognitionApiUrl, body, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? UPDATE USER SAVED RECOGNITION ========================================================================
export const setUserSavedRecognition = async (idrec) => {
  const body = {
    id_rekognisi: idrec,
  };
  const result = await axios_post(saveRecognitionApiUrl, body, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? UPDATE USER UNSAVED RECOGNITION ========================================================================
export const setUserUnsavedRecognition = async (idrec) => {
  const body = {
    id_rekognisi: idrec,
  };
  const result = await axios_post(unsaveRecognitionApiUrl, body, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? SAVE USER COMMENT RECOGNITION ==================================================================================
export const saveCommentUser = async (idrec, comment) => {
  const body = {
    comment: comment,
  };
  const result = await axios_post(
    `${recognitionApiUrl}/${idrec}/comment`,
    body,
    true
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? CHECK USER IS FILLED TRACER OR NOT ===================================================================
export const checkUserFilledTracer = async () => {
  const { username } = await getCurrentUser();
  const result = await axios_get(`${checkFilledTracerUrl}/${username}`);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? CHECK USER IS FILLED TRACER OR NOT ===================================================================
export const getDataMasterProvinsiTracer = async () => {
  const result = await axios_get(masterProvinsiTracer);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? CHECK USER IS FILLED TRACER OR NOT ===================================================================
export const getDataMasterTingKerjaTracer = async () => {
  const result = await axios_get(masterTingKejaTracer);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? CHECK USER IS FILLED TRACER OR NOT ===================================================================
export const getDataMasterPosisiTracer = async () => {
  const result = await axios_get(masterPosisiTracer);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? CHECK USER IS FILLED TRACER OR NOT ===================================================================
export const getDataMasterSumBiayaTracer = async () => {
  const result = await axios_get(masterSumBiayaTracer);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? CHECK USER IS FILLED TRACER OR NOT ===================================================================
export const getDataTracerUser = async () => {
  const { username } = await getCurrentUser();
  const result = await axios_get(`${dataTracerUrl}/${username}`);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? CHECK USER IS FILLED TRACER OR NOT ===================================================================
export const saveTracerStudyUser = async (data) => {
  const result = await axios_post(saveTracerUrl, data, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET SAVED RECOGNITION BY USER ==================================================================================
export const getSavedRecognition = async () => {
  const body = {};
  const result = await axios_post(savedRecognitionApiUrl, body, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET TAGS RECOGNITION BY USER ==================================================================================
export const getTaggedRecognition = async () => {
  const body = {};
  const result = await axios_post(taggedRecognitionApiUrl, body, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET TAGS RECOGNITION BY USER ==================================================================================
export const getHistoryConsultation = async (action) => {
  const header = {};
  const params = {
    approve:
      action === 10 ? "ACCEPTED" : action === 20 ? "PROCESSED" : "REJECTED",
  };
  const result = await axios_get(historyConsultationApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const checkRequestConsultation = async () => {
  const result = await axios_get(reqConsultationApiUrl);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET TAGS RECOGNITION BY USER ==================================================================================
export const sendRequestConsultation = async (message) => {
  const body = {
    topik: message,
  };
  const result = await axios_post(reqConsultationApiUrl, body, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//! KONSELOR API CONTROLLER ******************************************************************************************************
//? GET REKAP OVERVIEW RECOGNITION API REQUEST ===================================================================
export const getPlotingKonselor = async (action) => {
  const header = {};
  const params = {
    approve: action === 10 ? "ONGOING" : action === 20 ? "PENDING" : "COMPLETE",
  };
  const result = await axios_get(plottingConsultationApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET REKAP OVERVIEW RECOGNITION API REQUEST ===================================================================
export const updateResponPloting = async (id, respon) => {
  const body = {
    respon: respon,
  };
  const result = await axios_post(
    `${plottingConsultationApiUrl}/${id}`,
    body,
    true
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//! MITRA API CONTROLLER ********************************************************************************************************
//? REGISTER MITRA API REQUEST ==================================================================================
export const registerMitra = async (
  foto,
  logo,
  names,
  email,
  doc,
  desc,
  pic,
  phone,
  nowa,
  address
) => {
  const bodyFromData = new FormData();
  if (foto) {
    bodyFromData.append("foto", foto);
  }
  bodyFromData.append("logo", logo);
  bodyFromData.append("nama_mitra", names);
  bodyFromData.append("email", email);
  bodyFromData.append("file", doc);
  bodyFromData.append("deskripsi", desc);
  bodyFromData.append("pic_name", pic);
  bodyFromData.append("no_telp", phone);
  bodyFromData.append("no_wa", nowa);
  bodyFromData.append("alamat", address);
  const result = await axios_post(registerMitraApiUrl, bodyFromData, false);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET WAITING DATA MITRA API REQUEST ===============================================================
export const getMitraController = async ({
  approve = "ACCEPTED",
  search = null,
  page = 1,
  limit = 10,
  order = null,
  asc = null,
}) => {
  const header = {};
  const params = {
    approve,
    search,
    page,
    limit,
    order,
    asc,
  };
  const result = await axios_get(admMitraApiUrl, header, params);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? APPROVE OR REJECT MITRA API REQUEST ==================================================================
export const processMitraController = async ({
  idmitra,
  approve = "ACCEPTED",
  alasan = null,
}) => {
  const body = {
    idmitra,
    approve,
    alasan,
  };
  const result = await axios_post(
    `${admMitraApiUrl}/${idmitra}/approve`,
    body,
    true
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const saveLowonganController = async (
  flyer,
  position,
  type,
  email,
  prov,
  kab,
  prodi,
  desc,
  terms,
  condition,
  salary,
  notes,
  start,
  end
) => {
  const bodyFromData = new FormData();
  bodyFromData.append("file", flyer);
  bodyFromData.append("posisi", position);
  bodyFromData.append("id_jenispekerjaan", type);
  bodyFromData.append("contact_email", email);
  bodyFromData.append("prov_id", prov);
  bodyFromData.append("kota_id", kab);
  prodi.forEach((item) => {
    bodyFromData.append("jurusan[]", item);
  });
  bodyFromData.append("jobdesc", desc);
  bodyFromData.append("persyaratan", terms);
  bodyFromData.append("ketentuan", condition);
  bodyFromData.append("gaji", salary);
  bodyFromData.append("keterangan", notes);
  bodyFromData.append("berlaku_mulai", start);
  bodyFromData.append("berlaku_hingga", end);
  const result = await axios_post(jobsCareerApiUrl, bodyFromData, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const saveLowonganMitraTerdaftarController = async (
  flyer,
  username,
  position,
  type,
  email,
  kab,
  prodi,
  desc,
  terms,
  condition,
  salary,
  notes,
  start,
  end
) => {
  const bodyFromData = new FormData();
  bodyFromData.append("file", flyer);
  bodyFromData.append("id_mitra", username);
  bodyFromData.append("posisi", position);
  bodyFromData.append("id_jenispekerjaan", type);
  bodyFromData.append("contact_email", email);
  bodyFromData.append("kota_id", kab);
  prodi.forEach((item) => {
    bodyFromData.append("jurusan[]", item);
  });
  bodyFromData.append("jobdesc", desc);
  bodyFromData.append("persyaratan", terms);
  bodyFromData.append("ketentuan", condition);
  bodyFromData.append("gaji", salary);
  bodyFromData.append("keterangan", notes);
  bodyFromData.append("berlaku_mulai", start);
  bodyFromData.append("berlaku_hingga", end);
  const result = await axios_post(jobsCareerApiUrl, bodyFromData, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const saveLowonganMitraTidakAdaController = async (
  flyer,
  sampul,
  logo,
  type,
  position,
  kab,
  terms,
  jobdesc,
  condition,
  salary,
  start,
  end,
  contactEmail,
  notes,
  namaMitra,
  description,
  alamat,
  jenisUsaha,
  email,
  telp,
  wa,
  picName,
  picTelp,
  prodi
) => {
  const bodyFromData = new FormData();
  bodyFromData.append("file", flyer);
  bodyFromData.append("foto", sampul);
  bodyFromData.append("logo", logo);
  bodyFromData.append("id_jenispekerjaan", type);
  bodyFromData.append("posisi", position);
  bodyFromData.append("kota_id", kab);
  bodyFromData.append("persyaratan", terms);
  bodyFromData.append("jobdesc", jobdesc);
  bodyFromData.append("ketentuan", condition);
  bodyFromData.append("gaji", salary);
  bodyFromData.append("berlaku_mulai", start);
  bodyFromData.append("berlaku_hingga", end);
  bodyFromData.append("contact_email", contactEmail);
  bodyFromData.append("keterangan", notes);
  bodyFromData.append("nama_mitra", namaMitra);
  bodyFromData.append("deskripsi", description);
  bodyFromData.append("alamat", alamat);
  bodyFromData.append("jenis_usaha", jenisUsaha);
  bodyFromData.append("email", email);
  bodyFromData.append("no_telp", telp);
  bodyFromData.append("no_wa", wa);
  bodyFromData.append("pic_name", picName);
  bodyFromData.append("pic_telp", picTelp);
  prodi.forEach((item) => {
    bodyFromData.append("jurusan[]", item);
  });
  const result = await axios_post(jobsCareerNoMitApiUrl, bodyFromData, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const getLowonganController = async ({
  action = 10,
  username = null,
  approve = "ACCEPTED",
  search = null,
  page = 1,
  limit = 10,
  order = null,
  asc = null,
}) => {
  const header = {};
  const params = {
    approve,
    search,
    page,
    limit,
    order,
    asc,
  };
  const result = await axios_get(
    action === 10
      ? `${admMitraApiUrl}/${username}/lowongan`
      : mitLowonganApiUrl,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const processLowonganController = async ({
  idlowongan,
  username,
  approve = "ACCEPTED",
}) => {
  const body = {
    approve,
  };
  const result = await axios_post(
    `${admMitraApiUrl}/${username}/lowongan/${idlowongan}`,
    body,
    true
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const getApplyLowonganController = async ({
  id,
  search = null,
  page = 1,
  limit = 10,
  order = null,
  asc = null,
}) => {
  const header = {};
  const params = {
    search,
    page,
    limit,
    order,
    asc,
  };
  const result = await axios_get(
    `${mitApplyLowonganApiUrl}/${id}`,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const deleteLowonganController = async (id) => {
  const result = await axios_delete({
    url: `${jobsCareerApiUrl}/${id}`,
  });
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const uploadDocumentController = async (action, document) => {
  const bodyFromData = new FormData();
  if (action === 10) {
    bodyFromData.append("ijazah", document);
  } else if (action === 20) {
    bodyFromData.append("transkrip", document);
  } else {
    bodyFromData.append("cv", document);
  }

  const result = await axios_post(documentApiUrl, bodyFromData, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const applyLowonganController = async (id) => {
  const body = {
    id_lowongan: id,
  };
  const result = await axios_post(applyCareerApiUrl, body, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const getAlmApplyLowonganController = async () => {
  const result = await axios_get(applyCareerApiUrl);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const approvePelamarController = async ({
  id_user,
  approve,
  informasi,
  alasan,
  idlowongan,
}) => {
  const body = {
    id_user,
    approve,
    informasi,
    alasan,
  };
  const result = await axios_put(
    `${mitApproveLowonganApiUrl}/${idlowongan}`,
    body,
    true
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? SAVE NEW RECOGNITION API REQUEST ================================================================
export const saveEventController = async (
  images,
  rasio,
  title,
  body,
  category,
  start,
  end
) => {
  const bodyFromData = new FormData();
  bodyFromData.append("images", images);
  bodyFromData.append("rasio", rasio);
  bodyFromData.append("title", title);
  bodyFromData.append("body", body);
  bodyFromData.append("jenis_event", category);
  bodyFromData.append("tanggal_mulai", start);
  bodyFromData.append("tanggal_selesai", end);
  const result = await axios_post(eventApiUrl, bodyFromData, true);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET WAITING DATA MITRA API REQUEST ===============================================================
export const getEventController = async ({
  action = 10,
  approve = "ACCEPTED",
  page = 1,
  limit = 10,
}) => {
  const header = {};
  const params =
    action === 10
      ? {
          approve,
          page,
          limit,
        }
      : {
          page,
          limit,
        };
  const result = await axios_get(
    action === 10 ? admEventApiUrl : eventApiUrl,
    header,
    params
  );
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

//? GET WAITING DATA MITRA API REQUEST ===============================================================
export const getDetailEventController = async (slug) => {
  const result = await axios_get(`${eventApiUrl}/${slug}`);
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};

export const deleteEventController = async (id) => {
  const result = await axios_delete({
    url: `${eventApiUrl}/${id}`,
  });
  if (result.status) {
    return result;
  }
  return {
    status: false,
    message: result.message,
  };
};
