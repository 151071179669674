import Pusher from "pusher-js";
import Randomstring from "randomstring";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import {
  TbAlertCircle,
  TbAlertTriangle,
  TbMessageCircle,
  TbPlus,
  TbRefresh,
  TbSearch,
  TbSend,
  TbX,
} from "react-icons/tb";
import { useRecoilState } from "recoil";
import { currentIndexTabKonsul, gConsultationCategory } from "../../atoms";
import WAlmChats from "../../components/walm_chats";
import WAlmHistoryConsultation from "../../components/walm_list_consultation";
import {
  checkRequestConsultationController,
  requestConsultationController,
} from "../../controllers/alumni_controllers";
import { getConsultationCategoryController } from "../../controllers/global_controllers";
import AlumniLayout from "../../layouts/alumni_layout";
import {
  AlertDialog,
  ButtonHover,
  Card,
  Column,
  Form,
  FullScHeight,
  GridCol,
  Headline2,
  Headline3,
  IconButton,
  LoadingDialog,
  OutlineInput,
  OutlineTextArea,
  Overline,
  PrimaryButton,
  Row,
  SecondaryButton,
  SelectInput,
  SelectView,
  TabPanel,
  TabsLine,
  Text,
  TextButton,
  ToasterView,
  TransitionPage,
} from "../../shared/ui";
import {
  checkRequestConsultation,
  sendRequestConsultation,
} from "../../controllers/apicontrollers";

const ConsultastionAlumni = () => {
  const topicRef = useRef();
  const [currentCategoty, setCurrentCategory] = useState({
    label: "Pekerjaan",
    value: 1,
  });
  const [emptyRequest, setEmptyRequest] = useState({
    status: false,
    message: "",
  });
  const [currentMessage, setCurrentMessage] = useState(null);
  const [currentUpdate, setCurrentUpdate] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogWarning, setShowDialogWarning] = useState(false);
  const [reqUpdate, setReqUpdate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [channel, setChannel] = useState(null);
  const [errorTopic, setErrorTopic] = useState({
    status: false,
    message: "",
  });

  const listCategory = [
    {
      label: "Pekerjaan",
      value: 1,
    },
    {
      label: "Wirausaha",
      value: 2,
    },
    {
      label: "Studi Lanjut",
      value: 3,
    },
  ];

  const checkReqConsultation = async () => {
    const result = await checkRequestConsultation();
    setEmptyRequest({
      status: result.status,
      message: result.message,
    });
  };

  const onSelectedMessage = (message) => {
    if (currentMessage !== null) {
      if (currentMessage.status === "ONGOING") {
        unSubscribePusher(10, message);
      } else {
        if (message.status === "ONGOING") {
          setupPusher(message);
        } else {
          setCurrentMessage(message);
        }
      }
    } else {
      setupPusher(message);
    }
  };

  const requestConsultation = async () => {
    const message = topicRef.current.value;
    if (message.length > 0) {
      setShowDialog(false);
      setLoading(true);
      const result = await sendRequestConsultation(message);
      if (result.status) {
        topicRef.current.value = "";
        setTimeout(() => {
          setReqUpdate(Randomstring.generate(6));
          setLoading(false);
          toast(result.message);
        }, 200);
      } else {
        setTimeout(() => {
          setLoading(false);
          toast(result.message);
        }, 200);
      }
    } else {
      topicRef.current.focus();
      setErrorTopic({
        status: true,
        message: "Masukkan topik konsultasi",
      });
    }
  };

  const setupPusher = (message) => {
    if (message.status === "ONGOING") {
      const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY_DEV, {
        cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER_DEV,
      });
      const channel = pusher.subscribe(`konsultasi_${message.id_konsultasi}`);
      channel.bind("event-name", (data) => {
        console.log("Received data:", data);
        updateMessage(data);
      });
    }
    setCurrentMessage(message);
  };

  const unSubscribePusher = async (action, message) => {
    await channel.unsubscribe(`konsultasi_${currentMessage.id_konsultasi}`);
    if (action === 10) {
      setupPusher(message);
    } else {
      setCurrentMessage(null);
    }
  };

  const updateMessage = (data) => {
    setCurrentUpdate(data);
  };

  const showDialogConsultation = () => {
    if (emptyRequest.status) {
      setErrorTopic({
        status: false,
        message: "",
      });
      setShowDialog(true);
    } else {
      setShowDialogWarning(true);
    }
  };

  const onChangeSelect = (result) => {
    setCurrentCategory(result);
  };

  useEffect(() => {
    checkReqConsultation();
  }, []);

  return (
    <>
      <AlumniLayout idpage="consultation" removePadding>
        <TransitionPage>
          <Row className="divide-x divide-gray-200/50">
            <FullScHeight className="w-full sm:max-w-md sm:overflow-y-auto scrollbar-hide">
              <Column className="pt-14 sm:pt-0 divide-y divide-slate-200/80">
                <Column className="px-6 pt-8 pb-6 gap-5 bg-white">
                  <Row className="justify-between">
                    <Headline2>Konsultasi</Headline2>
                    <IconButton
                      bordered
                      color="indigo"
                      onClick={showDialogConsultation}
                    >
                      <TbPlus className="h-5 w-5" />
                    </IconButton>
                  </Row>
                  <OutlineInput
                    inputWidth="w-full"
                    placeholder="Cari konsultasi"
                  />
                </Column>

                <WAlmHistoryConsultation
                  currentMessage={currentMessage ?? {}}
                  action={20}
                  onSelectedMessage={onSelectedMessage}
                  reqUpdate={reqUpdate}
                />
                <WAlmHistoryConsultation
                  currentMessage={currentMessage ?? {}}
                  action={30}
                  onSelectedMessage={onSelectedMessage}
                />
                <WAlmHistoryConsultation
                  currentMessage={currentMessage ?? {}}
                  action={10}
                  onSelectedMessage={onSelectedMessage}
                  updateMessage={currentUpdate}
                  reqUpdate={reqUpdate}
                />
                {/* <TabsLine className='sticky top-14 sm:top-0 bg-white' ulClassName='w-full justify-evenly' selectedTabIdx={currentTabIndex} onChangeTabIndex={onChangeTabIndex}>
                            <TabPanel removePadding title='Disetujui'>
                                <WAlmHistoryConsultation action={10} onSelectedMessage={onSelectedMessage} updateMessage={currentUpdate} />
                            </TabPanel>
                            <TabPanel removePadding title='Menunggu'>
                                <WAlmHistoryConsultation action={20} onSelectedMessage={onSelectedMessage} />
                            </TabPanel>
                            <TabPanel removePadding title='Ditolak'>
                                <WAlmHistoryConsultation action={30} onSelectedMessage={onSelectedMessage} />
                            </TabPanel>
                        </TabsLine> */}
              </Column>
            </FullScHeight>
            <WAlmChats
              currentMessage={currentMessage}
              channel={channel}
              onUpdateMessage={updateMessage}
              showDialogConsultation={showDialogConsultation}
            />
          </Row>
        </TransitionPage>
      </AlumniLayout>
      <AlertDialog
        className="max-w-sm"
        show={showDialog}
        onClose={() => setShowDialog(false)}
      >
        <Column className="divide-y divide-gray-200/80">
          <Column className="px-5 py-10 items-center gap-8">
            <Card className="p-1.5 rounded-2xl bg-indigo-50/50 border-indigo-200/50">
              <TbMessageCircle className="h-14 w-14 stroke-1.5 text-indigo-600" />
            </Card>
            <Column className="gap-2">
              <Headline3 className="text-center">Konsultasi Baru</Headline3>
              <Text className="text-center">
                Temukan solusi yang tepat pada sesi konsultasi bersama konselor
                terbaik
              </Text>
            </Column>
            <Column className="gap-5">
              <SelectInput
                zindex="z-10"
                label="Kategori Konsultasi"
                initial={currentCategoty}
                options={listCategory}
                onChange={onChangeSelect}
              />
              <Column className="gap-1.5">
                <OutlineTextArea
                  id="topic"
                  refs={topicRef}
                  label="Topik Konsultasi"
                  className={`w-full p-3 ${
                    errorTopic.status &&
                    "border-red-200 focus:border-red-500 focus:ring-red-50"
                  }`}
                  initialRow={5}
                  maxRows={6}
                  maxLength={200}
                  placeholder="Tulis topik"
                />
                {errorTopic.status && (
                  <Row className="gap-1">
                    <TbAlertCircle className="h-4 w-4 text-red-500 my-auto" />
                    <Overline className="text-red-600 my-auto">
                      {errorTopic.message}
                    </Overline>
                  </Row>
                )}
              </Column>
            </Column>
          </Column>
          <GridCol className="gap-0 divide-x divide-gray-200/80">
            <TextButton
              onClick={() => {
                setShowDialog(false);
              }}
              className="w-full py-4 text-base hover:bg-gray-50/50"
            >
              Batal
            </TextButton>
            <TextButton
              onClick={requestConsultation}
              color="indigo"
              className="w-full py-4 text-base hover:bg-indigo-50/50"
            >
              Kirim
            </TextButton>
          </GridCol>
        </Column>
      </AlertDialog>
      <AlertDialog
        className="max-w-xs"
        show={showDialogWarning}
        onClose={() => setShowDialogWarning(false)}
      >
        <Column className="divide-y divide-gray-200/80">
          <Column className="px-5 py-10 items-center gap-8">
            <Card className="p-1.5 rounded-2xl bg-indigo-50/50 border-indigo-200/50">
              <TbMessageCircle className="h-14 w-14 stroke-1.5 text-indigo-600" />
            </Card>
            <Column className="gap-2">
              <Headline3 className="text-center">Konsultasi Baru</Headline3>
              <Text className="text-center">{emptyRequest.message}</Text>
            </Column>
          </Column>
          <TextButton
            onClick={() => {
              setShowDialogWarning(false);
            }}
            className="w-full py-4 text-base hover:bg-gray-50/50"
          >
            Oke
          </TextButton>
        </Column>
      </AlertDialog>
      {/* <SideNotifPanel showNotifPanel={showDialog} hideNotifPanel={(value) => { setShowDialog(value) }} closeBtnAlign='right' className='sm:max-w-md'>
        <Column className='h-screen divide-y divide-gray-200/50 overflow-y-auto scrollbar-hide'>
            <Column className='gap-5 p-5 sm:p-8'>
                <Card className='p-1.5 bg-none rounded-2xl'>
                    <TbMessageCircle className='h-12 w-12 stroke-1.5 text-indigo-600' />
                </Card>
                <Column className='gap-1'>
                    <Headline3>
                        Konsultasi Baru
                    </Headline3>
                    <Text>
                        Temukan solusi yang tepat pada sesi konsultasi bersama konselor terbaik kami
                    </Text>
                </Column>
            </Column>
            <Column className='h-full gap-8 p-5 sm:p-8'>
                <Column className='gap-1.5'>
                    <Caption>
                        Kategori Konsultasi
                    </Caption>
                    <GridCol className='gap-3 grid-cols-1 sm:grid-cols-2'>
                        <label htmlFor="1" className={`w-full inline-flex items-center gap-3 border p-4 rounded-lg text-base jakarta font-medium cursor-pointer transition-all duration-300 ${currentCategory === 10 ? 'bg-indigo-50 border-indigo-200 text-indigo-700' : 'bg-slate-50/50 border-gray-200/80 text-gray-700'}`}>
                            <TbBriefcase className='h-7 w-7 stroke-1.5' />
                            <span>Pekerjaan</span>
                            <input id='1' onChange={onChangeKonsultasi} name='konsultasi' checked={currentCategory === 10} type="radio" value={10} hidden />
                        </label>
                        <label htmlFor="2" className={`w-full inline-flex items-center gap-3 border p-4 rounded-lg text-base jakarta font-medium cursor-pointer transition-all duration-300 ${currentCategory === 20 ? 'bg-indigo-50 border-indigo-200 text-indigo-700' : 'bg-slate-50/50 border-gray-200/80 text-gray-700'}`}>
                            <TbPlant2 className='h-7 w-7 stroke-1.5' />
                            <span>Wirausaha</span>
                            <input id='2' onChange={onChangeKonsultasi} name='konsultasi' checked={currentCategory === 20} type="radio" value={20} hidden />
                        </label>
                        <label htmlFor="3" className={`w-full inline-flex items-center gap-3 border p-4 rounded-lg text-base jakarta font-medium cursor-pointer transition-all duration-300 ${currentCategory === 30 ? 'bg-indigo-50 border-indigo-200 text-indigo-700' : 'bg-slate-50/50 border-gray-200/80 text-gray-700'}`}>
                            <TbSchool className='h-7 w-7 stroke-1.5' />
                            <span>Studi Lanjut</span>
                            <input id='3' onChange={onChangeKonsultasi} name='konsultasi' checked={currentCategory === 30} type="radio" value={30} hidden />
                        </label>
                    </GridCol>
                </Column>
                <OutlineTextArea refs={topicRef} label='Pesan Konsultasi' id='topic' className='w-full p-3' initialRow={5} maxRows={6} placeholder='Tulis pesan disini' />
                <PrimaryButton className='w-fit pr-5' color='indigo' leadingIcon={<TbSend className='h-5 w-5' />} onClick={requestConsultation}>
                    Kirim Permintaan
                </PrimaryButton>
                <div className='w-full h-32'></div>
            </Column>
        </Column>
    </SideNotifPanel> */}
      <LoadingDialog show={loading} message="Mengirim pengajuan..." />
      <ToasterView className="bg-black" textColor="text-white" />
    </>
  );
};

export default ConsultastionAlumni;
