import moment from "moment";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { getFullNameUser } from "../../controllers/apicontrollers";
import { getViewDisplay } from "../../shared/lib";
import {
  Caption,
  Column,
  DotSeparated,
  Headline1,
  HorizontalDivider,
  Row,
  ShareFBButton,
  ShareTelegramButton,
  ShareWAButton,
  ShareXButton,
  ShimmerAnimation,
  ShimmerItem,
  Text,
  Title,
} from "../../shared/ui";
import ListCommentDetailRecognition from "../list/list_comment_detail_recognition";
import SAlmDetailLikeRecognition from "../shimmer/salm_detail_like_recognition";
import WGridImageRecognition from "../wgrid_image_recognition";
import WListImageRecognition from "../wlist_image_recognition";
import WCommentDetailrecognition from "./w_comment_detail_recognition";

const WLikeDetailrecognition = lazy(() =>
  import("../../components/other/w_like_detail_recognition")
);

function WDetailRecognition({ recognition }) {
  const [fullName, setFullName] = useState(null);
  const [listTags, setListTags] = useState([]);
  const [sumLike, setSumLike] = useState(recognition.likes);

  const getFullNameOfUser = async (identify, iduser) => {
    const result = await getFullNameUser(identify, iduser);
    if (result.status) {
      setFullName(result.data);
    }
  };

  const getFullNameOfTagUser = async (tags, idx, temp = []) => {
    const result = await getFullNameUser(
      tags[idx].id_identify,
      tags[idx].id_user
    );
    if (result.status) {
      const tag = {
        id_identify: tags[idx].id_identify,
        id_user: tags[idx].id_user,
        name: result.data.toLowerCase(),
      };
      temp.push(tag);
    }
    const nextIdx = idx + 1;
    if (nextIdx <= tags.length - 1) {
      getFullNameOfTagUser(tags, nextIdx, temp);
    } else {
      const tempSort = temp.sort((a, b) => (a.id_user < b.id_user ? -1 : 1));
      setListTags(tempSort);
    }
  };

  const onChangeLike = (action) => {
    if (action === 10) {
      setSumLike(sumLike + 1);
    } else {
      setSumLike(sumLike - 1);
    }
  };

  useEffect(() => {
    getFullNameOfUser(recognition.id_identify, recognition.id_user);
    if (recognition.tags.length > 0) {
      getFullNameOfTagUser(recognition.tags, 0);
    }
  }, []);

  return (
    <Column className={"gap-y-5"}>
      <Headline1 className={"font-semibold"}>{recognition.title}</Headline1>
      <Caption>
        {moment(recognition.created_at).format("DD/MM/YYYY HH.mm")} WIB
      </Caption>
      {recognition.images.length > 0 ? (
        recognition.view === "list" ? (
          <WListImageRecognition
            images={recognition.images}
            aspectRatio={recognition.rasio}
            action={20}
          />
        ) : (
          <WGridImageRecognition
            images={recognition.images}
            aspectRatio={recognition.rasio}
            action={20}
          />
        )
      ) : null}
      <div
        className={"text-gray-800 font-inter text-justify text-base mt-2"}
        dangerouslySetInnerHTML={{
          __html: recognition.body
            .replaceAll("<ul>", '<ul class="list-disc space-y-2 pl-5">')
            .replaceAll("<ol>", '<ol class="list-decimal pl-6 space-y-2">')
            .replaceAll(
              "<img src=",
              '<img style="border-radius: 0.75rem; border-width: 1px;" loading="lazy" src='
            )
            .replaceAll("undefined", null),
        }}
      />
      <Suspense fallback={<SAlmDetailLikeRecognition />}>
        <WLikeDetailrecognition
          id={recognition.id_rekognisi}
          onChangeLike={onChangeLike}
        />
      </Suspense>
      <Column className={"gap-y-2"}>
        <Row className={"gap-x-1 flex-wrap items-center"}>
          <Text>Diposting oleh</Text>
          {fullName !== null ? (
            <Text className={"font-semibold font-manrope capitalize"}>
              {fullName}
            </Text>
          ) : (
            <ShimmerAnimation>
              <ShimmerItem className={"h-4 w-28 rounded-md"} />
            </ShimmerAnimation>
          )}
          {listTags.length > 0 && <Text>bersama</Text>}
          {listTags.length > 0 && (
            <div>
              <Text className={"font-semibold font-manrope capitalize"}>
                {listTags[0].name}
              </Text>
            </div>
          )}
          {listTags.length > 1 && (
            <Text>
              dan{" "}
              <span className={"font-semibold font-manrope capitalize"}>
                {listTags.length - 1} lainnya
              </span>
            </Text>
          )}
        </Row>
        <Row className={"flex-wrap gap-y-2 items-center"}>
          <Caption>{getViewDisplay(recognition.viewer ?? 0)}x dilihat</Caption>
          <DotSeparated />
          {sumLike > 0 && (
            <Row className={"w-fit"}>
              <Caption>{getViewDisplay(sumLike)} menyukai</Caption>
              <DotSeparated />
            </Row>
          )}

          <Caption>
            {recognition.comments.length > 0
              ? getViewDisplay(recognition.comments.length ?? 0)
              : "Belum ada"}{" "}
            komentar
          </Caption>
        </Row>
      </Column>
      <HorizontalDivider className="my-4" />
      <Title>Bagikan Berita</Title>
      <Row className="gap-x-3">
        <ShareFBButton location={window.location.href} />
        <ShareXButton location={window.location.href} />
        <ShareWAButton location={window.location.href} />
        <ShareTelegramButton location={window.location.href} />
      </Row>
      <HorizontalDivider className="my-4" />
      <WCommentDetailrecognition id={recognition.id_rekognisi} />
      <ListCommentDetailRecognition comments={recognition.comments} />
    </Column>
  );
}

export default WDetailRecognition;
