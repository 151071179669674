import { useState } from "react";
import {
  Outlet,
  ScrollRestoration,
  useLocation,
  useNavigation,
} from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { FullScreen, ToasterView } from "../shared/ui";

import randomstring from "randomstring";
import AlmAppBar from "../components/appbar/alm_appbar";
import AlmSidebar from "../components/sidebar/alm_sidebar";
import { almConsultationRoute } from "../shared/variable";
import AlmConsultationSidebar from "../components/sidebar/alm_consultation_sidebar";

TopBarProgress.config({
  barColors: {
    0: "#B71C1C",
    0.6: "#D50000",
    "1.0": "#E53935",
  },
  barThickness: "3.0",
  shadowColor: "#ffff",
  shadowBlur: "0",
});

const AlumniLayout = () => {
  const location = useLocation();
  const { state } = useNavigation();

  const [showOnMobile, setShowOnMobile] = useState("0");

  const onShowSidebar = () => {
    setShowOnMobile(randomstring.generate(10));
  };

  return (
    <>
      {state === "loading" && <TopBarProgress />}

      <AlmAppBar onShowSidebar={onShowSidebar} />
      <AlmSidebar showOnMobile={showOnMobile} />
      <FullScreen className={`relative`}>
        <ScrollRestoration
          getKey={(location) => {
            return location.pathname;
          }}
        />
        <Outlet />
      </FullScreen>
      <ToasterView />
    </>
  );
};

export default AlumniLayout;
