import React from "react";
import { Outlet } from "react-router-dom";
import AlmConsultationSidebar from "../../../components/sidebar/alm_consultation_sidebar";
import { Column } from "../../../shared/ui";

function AlmConsultationPage() {
  return (
    <Column className={"w-full"}>
      <AlmConsultationSidebar />
      <Column className={"w-full pt-20 pb-16 sm:pt-24 lg:pt-32 xl:pl-[18rem]"}>
        <Outlet />
      </Column>
    </Column>
  );
}

export default AlmConsultationPage;
