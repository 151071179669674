import moment from "moment";
import React, { useEffect, useState } from "react";
import { getFullNameUser } from "../../../controllers/apicontrollers";
import {
  Avatar,
  Caption,
  Column,
  DotSeparated,
  Row,
  ShimmerAnimation,
  ShimmerItem,
  Text,
  TextButton,
} from "../../../shared/ui";

const ItemCommentRecognition = ({
  comment,
  action = 0,
  onApprove,
  onReject,
  onUndo,
  onDelete,
}) => {
  return (
    <Column className={"flex flex-row gap-x-3 mb-4"}>
      <Column className={"w-fit"}>
        <Avatar className={"w-8 h-8"} />
      </Column>
      <Column className={"gap-y-3"}>
        <Column className={"gap-y-1"}>
          <Text className={"text-gray-800 font-semibold font-manrope"}>
            {comment.fullName || comment.nama}
          </Text>

          <Caption>
            {moment(comment.created_at).format("DD/MM/YYYY HH.mm")} WIB
          </Caption>
        </Column>
        <Text className="text-gray-800 whitespace-pre-line mt-1">
          {comment.comment}
        </Text>
        {action !== 0 && (
          <Row className={"w-fit mt-2.5"}>
            {action === 10 ? (
              <Row className="w-fit">
                <TextButton
                  color="indigo"
                  className={"text-xs"}
                  onClick={() => onApprove(comment.id_comment_rekognisi)}
                >
                  Approve
                </TextButton>
                <DotSeparated />
                <TextButton
                  color="red"
                  className={"text-xs"}
                  onClick={() => onReject(comment.id_comment_rekognisi)}
                >
                  Reject
                </TextButton>
              </Row>
            ) : (
              <Row className="w-fit gap-x-4">
                <TextButton
                  color="indigo"
                  className={"text-xs"}
                  onClick={() => onUndo(comment.id_comment_rekognisi)}
                >
                  Proses Ulang
                </TextButton>
                {/* <TextButton
                  color="red"
                  className={"text-xs"}
                  onClick={() => onDelete(comment.id_comment_rekognisi)}
                >
                  Delete
                </TextButton> */}
              </Row>
            )}
          </Row>
        )}
      </Column>
    </Column>
  );
};

export default ItemCommentRecognition;
