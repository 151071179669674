import React, { useEffect, useState } from "react";
import { TbMessagePlus, TbMessageSearch } from "react-icons/tb";
import { useLoaderData, useNavigate } from "react-router-dom";
import ListConsultation from "../../../components/list/list_consultation";
import { getConsultationController } from "../../../controllers/alumni_controllers";
import {
  ButtonHover,
  Column,
  Headline3,
  TertiaryButton,
  Text,
} from "../../../shared/ui";
import { almCreateConsultationRoute } from "../../../shared/variable";

function AlmWaitingConsultationPage() {
  const result = useLoaderData();

  const navigateTo = useNavigate();

  const [listConsultation, setListConsultation] = useState([]);

  const getConsultationFromLoader = () => {
    if (result.status) {
      setListConsultation(result.data);
    }
  };

  useEffect(() => {
    getConsultationFromLoader();
  }, []);

  console.log("List waiting konsultasi:", listConsultation);

  return (
    <Column className={"w-full max-w-4xl mx-auto gap-y-5 sm:gap-y-8 sm:px-14"}>
      {listConsultation.length > 0 ? (
        <ListConsultation action={10} consultation={listConsultation} />
      ) : (
        <Column
          className={
            "items-center gap-y-6 px-4 py-16 bg-white border-y lg:border-x lg:border-gray-200"
          }
        >
          <div className={"p-4 bg-gray-50 rounded-full"}>
            <TbMessageSearch className={"h-20 w-20 text-gray-300 stroke-1.5"} />
          </div>
          <Column className={"items-center gap-y-2"}>
            <Headline3 className={"text-gray-800"}>
              Menunggu Verifikasi
            </Headline3>
            <Text className={"text-center text-gray-500"}>
              Saat ini belum terdapat pengajuan konsultasi yang menunggu
              verifikasi,
              <br />
              buat sesi konsultasi baru untuk memulai konsultasi bersama
              konselor terbaik
            </Text>
          </Column>
          <ButtonHover>
            <TertiaryButton
              pills
              color="indigo"
              className={"pl-5 pr-6"}
              leadingIcon={<TbMessagePlus className={"h-5 w-5 my-auto"} />}
              onClick={() => navigateTo(almCreateConsultationRoute)}
            >
              Konsultasi Baru
            </TertiaryButton>
          </ButtonHover>
        </Column>
      )}
    </Column>
  );
}

export default AlmWaitingConsultationPage;

export const getWaitingConsultation = async () => {
  const result = await getConsultationController(20);
  return result;
};
