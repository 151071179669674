import moment from "moment";
import React from "react";
import {
  TbArrowDown,
  TbArrowRight,
  TbArrowUpRight,
  TbCircleCheck,
  TbInfoCircle,
  TbPin,
  TbX,
} from "react-icons/tb";
import {
  ButtonHover,
  Caption,
  Column,
  Headline3,
  HorizontalDivider,
  IconButton,
  Image,
  PrimaryButton,
  Row,
  SimpleAlert,
  Subtitle,
  TertiaryButton,
  Text,
} from "../../shared/ui";

function WDetailCareer({
  action,
  career,
  showApply,
  onApply,
  onDetail,
  onClose,
}) {
  return (
    <Column className={"h-screen overflow-y-auto scrollbar-hide scroll-smooth"}>
      <Row
        className={
          "px-4 pt-4 pb-3 justify-between items-start bg-white border-b border-gray-200 sticky top-0 sm:px-10 sm:pt-8 sm:pb-5"
        }
      >
        <Row className={"gap-x-3 items-center"}>
          <Image
            src={career.logo}
            className={"p-1 border rounded-xl bg-white"}
            imgClasName={"w-16 aspect-square bg-white"}
            hoverScale={false}
          />
          <Column className="gap-y-2">
            <Headline3 className={"hover:text-indigo-600"}>
              {career.posisi}
            </Headline3>
            <Text>{career.nama_mitra}</Text>
          </Column>
        </Row>
        <IconButton bordered className={"p-2"} onClick={onClose}>
          <TbX className={"h-5 w-5"} />
        </IconButton>
      </Row>
      {(!showApply || career.applied) && action === 10 ? (
        <SimpleAlert
          borderLeft
          type={!showApply ? "warning" : "success"}
          icon={
            !showApply ? (
              <TbInfoCircle className={"h-5 w-5 my-auto text-orange-600"} />
            ) : (
              <TbCircleCheck className={"h-5 w-5 my-auto text-green-600"} />
            )
          }
          className={"rounded-none px-4 sm:px-10"}
        >
          {!showApply
            ? "Masuk ke akun Anda untuk apply di lowongan ini."
            : "Anda berhasil apply di lowongan ini."}
        </SimpleAlert>
      ) : null}
      <Column className={"px-4 py-4 gap-y-5 sm:px-10 sm:py-8 sm:gap-y-8"}>
        <Column className={"gap-y-2"}>
          <Text className={"font-medium"}>{career.jenis}</Text>
          <Text>
            <span className={"font-medium"}>Gaji: </span>
            {career.gaji}
          </Text>
          <Text>
            <span className={"font-medium"}>Lokasi: </span>
            {career.kota}
          </Text>
        </Column>
        <Column className={"gap-y-2"}>
          <Subtitle className={"font-semibold"}>Deskripsi Pekerjaan</Subtitle>
          <div
            className="p-3 text-gray-800 font-inter text-base rounded-xl bg-gray-50 border border-gray-200 sm:p-4"
            dangerouslySetInnerHTML={{
              __html: career.jobdesc
                .replaceAll("<ul>", '<ul class="list-disc space-y-2 pl-5">')
                .replaceAll("<ol>", '<ol class="list-decimal pl-6 space-y-2">')
                .replaceAll("undefined", ""),
            }}
          />
        </Column>
        <Column className={"gap-y-2"}>
          <Subtitle className={"font-semibold"}>Persyaratan</Subtitle>
          <div
            className="p-3 text-gray-800 font-inter text-base rounded-xl bg-gray-50 border border-gray-200 sm:p-4 "
            dangerouslySetInnerHTML={{
              __html: career.persyaratan
                .replaceAll("<ul>", '<ul class="list-disc space-y-2 pl-5">')
                .replaceAll("<ol>", '<ol class="list-decimal pl-6 space-y-2">')
                .replaceAll("undefined", ""),
            }}
          />
        </Column>
        <Column className={"gap-y-2"}>
          <Subtitle className={"font-semibold"}>Ketentuan</Subtitle>
          <div
            className="p-3 text-gray-800 font-inter text-base rounded-xl bg-gray-50 border border-gray-200 sm:p-4"
            dangerouslySetInnerHTML={{
              __html: career.ketentuan
                .replaceAll("<ul>", '<ul class="list-disc space-y-2 pl-5">')
                .replaceAll("<ol>", '<ol class="list-decimal pl-6 space-y-2">')
                .replaceAll("undefined", ""),
            }}
          />
        </Column>
        {career.keterangan?.length > 0 && (
          <Column className={"gap-y-2"}>
            <Subtitle className={"font-semibold"}>Keterangan</Subtitle>
            <Text
              className={
                "p-3 rounded-xl bg-gray-50 border border-gray-200 sm:p-4"
              }
            >
              {career.keterangan?.length > 0
                ? career.keterangan
                : "Tidak ada keterangan"}
            </Text>
          </Column>
        )}
        <Column className={"gap-y-2"}>
          <Subtitle className={"font-semibold"}>Waktu</Subtitle>
          <Column className={"gap-x-5 gap-y-4 sm:flex-row"}>
            <Column className="p-3 gap-y-1 border border-gray-200 rounded-xl bg-gray-50 sm:p-4">
              <Caption>Dibuka mulai:</Caption>
              <Text className="font-inter">
                {moment(career.berlaku_mulai).format("DD MMMM YYYY")}
              </Text>
            </Column>
            <div className="w-fit self-center flex h-7 aspect-square rounded-full bg-gray-700 my-auto justify-center items-center">
              <TbArrowDown className="h-5 w-5 text-white sm:hidden" />
              <TbArrowRight className="h-5 w-5 text-white hidden sm:flex" />
            </div>
            <Column className="p-3 gap-y-1 border border-gray-200 rounded-xl bg-gray-50 sm:p-4">
              <Caption>Ditutup pada:</Caption>
              <Text className="font-inter">
                {moment(career.berlaku_hingga).format("DD MMMM YYYY")}
              </Text>
            </Column>
          </Column>
        </Column>
      </Column>
      <Caption className={"px-4 pt-3 pb-6 sm:px-10"}>
        Lowongan dipublis {moment(career.created_at).format("DD/MM/YYYY HH.mm")}{" "}
        WIB
      </Caption>
      <HorizontalDivider />
      <Column className={"gap-y-2 px-4 pt-3 pb-6 sm:px-10 sm:pt-8 sm:pb-32"}>
        <Subtitle className={"font-semibold"}>
          Tentang Perusahaan/Mitra
        </Subtitle>
        <Column
          className={
            "px-5 py-4 gap-y-5 rounded-xl bg-gray-50 border border-gray-200"
          }
        >
          <Text>{career.deskripsi}</Text>
          <Column className={"gap-y-1"}>
            <Caption>Email</Caption>
            <Text>{career.contact_email ?? "Belum tersedia"}</Text>
          </Column>
          <Column className={"gap-y-1"}>
            <Caption>Nomor Telepon</Caption>
            <Text>{career.contact_number ?? "Belum tersedia"}</Text>
          </Column>
          <Column className={"gap-y-1"}>
            <Caption>Nomor WhatsApp</Caption>
            <Text>{career.no_wa ?? "Belum tersedia"}</Text>
          </Column>
          <Column className={"gap-y-1"}>
            <Caption>Alamat</Caption>
            <Text>{career.alamat ?? "Belum tersedia"}</Text>
          </Column>
        </Column>
      </Column>
      <Row
        className={
          "px-4 pt-4 pb-4 gap-x-3 gap-y-3 justify-between items-center border-t border-gray-200 sm:px-10 sm:pb-4 sm:fixed sm:inset-x-0 sm:bottom-0 sm:bg-white"
        }
      >
        <ButtonHover>
          <TertiaryButton
            pills
            leadingIcon={<TbX className="h-5 w-5 my-auto" />}
            className={"pl-5 pr-6"}
            onClick={onClose}
          >
            Tutup
          </TertiaryButton>
        </ButtonHover>
        {showApply &&
        career.applied === 0 &&
        career.appliable === 1 &&
        !career.id_mitra === "admintracer" ? (
          <ButtonHover>
            <PrimaryButton
              pills
              color={"indigo"}
              leadingIcon={<TbPin className="h-5 w-5 my-auto" />}
              className={"pl-5 pr-6"}
              onClick={() => onApply(career.id_lowongan)}
            >
              Apply Sekarang
            </PrimaryButton>
          </ButtonHover>
        ) : (
          career.applied === 1 && (
            <PrimaryButton
              pills
              color={"green"}
              leadingIcon={<TbArrowUpRight className="h-5 w-5 my-auto" />}
              className={"pl-5 pr-6"}
              onClick={onDetail}
            >
              Lihat Detail
            </PrimaryButton>
          )
        )}
      </Row>
    </Column>
  );
}

export default WDetailCareer;
