import Randomstring from "randomstring";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import {
  TbChecks,
  TbCircleDashed,
  TbInfoCircle,
  TbMessageCircle,
  TbMessageCircleOff,
  TbSend,
  TbStarFilled,
  TbTrash,
  TbUserCircle,
  TbX,
} from "react-icons/tb";
import {
  deleteChatKonsultasi,
  finishKonsultasi,
  getChatKonsultasi,
  sendChatKonsultasi,
} from "../controllers/apicontrollers";
import {
  AlertDialog,
  Avatar,
  ButtonHover,
  Caption,
  Card,
  Column,
  DropDownMenu,
  Form,
  FullScHeight,
  GridCol,
  Headline3,
  IconButton,
  LargeHeadline,
  LoadingDialog,
  OutlineTextArea,
  PrimaryButton,
  Row,
  Subtitle,
  TertiaryButton,
  Text,
  TextButton,
  Title,
} from "../shared/ui";
import { getCurrentUser } from "../shared/user";
import CKonBubbleMessage from "./cards/ckon_bubble_message";
import avatar1 from "../images/avatar1.png";
import avatar3 from "../images/avatar3.png";
import TextareaAutosize from "react-autosize-textarea";

const WKonChats = ({
  currentMessage = null,
  channel = null,
  onApprove,
  onFinish,
  onUpdateMessage,
}) => {
  const messageRef = useRef();
  const [insert, setInsert] = useState(null);
  const [listChats, setListChats] = useState([]);
  const [newMessage, setNewMessage] = useState(null);
  const [replyMessage, setReplyMessage] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogDone, setShowDialogDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hideTopic, setHideTopic] = useState(false);

  const listRate = [
    { rate: 1, label: "Sangat Tidak Puas" },
    { rate: 2, label: "Tidak Puas" },
    { rate: 3, label: "Cukup Puas" },
    { rate: 4, label: "Puas" },
    { rate: 5, label: "Sangat Puas" },
  ];

  const onSubmitForm = (event) => {
    event.preventDefault();
    const message = event.target.message.value;
    if (message.length > 0) {
      saveMessage(message);
    }
  };

  const enterKeyPressed = (event) => {
    if (event.keyCode == 13 && event.shiftKey == false) {
      event.preventDefault();
      const message = event.target.value;
      if (message.length > 0) {
        saveMessage(message);
      }
    }
  };

  const saveMessage = async (message) => {
    const result = await sendChatKonsultasi(
      currentMessage.id_konsultasi,
      message,
      replyMessage !== null ? replyMessage.id_chat_konsultasi : null
    );
    if (result.status) {
      insertNewMessage(10, result.data);
    }
  };

  const insertNewMessage = (type, data) => {
    const listChatsTemp = listChats;
    const newMessage = {
      chat: data.chat,
      created_at: data.created_at,
      from_who: data.from_who,
      id_chat_konsultasi: data.id_chat_konsultasi,
      id_konsultasi: currentMessage.id_konsultasi,
      replay_to: data.replay_to,
      updated_at: data.created_at,
    };
    listChatsTemp.unshift(newMessage);
    setListChats(listChatsTemp);
    setInsert(Randomstring.generate(6));
    onUpdateMessage({
      id: currentMessage.id_konsultasi,
      message: data.chat,
      date: data.created_at,
    });
    if (type === 10) {
      messageRef.current.value = "";
      if (replyMessage !== null) {
        setReplyMessage(null);
      }
    } else {
      setNewMessage(null);
    }
  };

  const listenPusher = () => {
    const { username, kode } = getCurrentUser();
    channel.bind(`${kode}_${username}`, function (data) {
      setNewMessage(data);
    });
  };

  const getAllMessage = async () => {
    const result = await getChatKonsultasi(currentMessage.id_konsultasi);
    console.log("konsultasi slug", currentMessage.id_konsultasi);
    if (result.status) {
      setListChats(result.data);
    }
  };

  const onChangeReplyMessage = (message) => {
    setReplyMessage(message);
    messageRef.current?.focus();
  };

  const onChangeDeleteMessage = async (message) => {
    setDeleteMessage(message);
    setShowDialog(true);
  };

  const reqDeleteMessage = async () => {
    setShowDialog(false);
    setLoading(true);
    const result = await deleteChatKonsultasi(deleteMessage.id_chat_konsultasi);
    if (result.status) {
      setTimeout(() => {
        const chatTemp = listChats.filter((chat) => chat !== deleteMessage);
        setListChats(chatTemp);
        setDeleteMessage(null);
        setLoading(false);
        toast(result.message);
      }, 200);
    } else {
      setTimeout(() => {
        setLoading(false);
        toast(result.message);
      }, 200);
    }
  };

  const reqFinishConsultation = async () => {
    setShowDialogDone(false);
    setLoading(true);
    const result = await finishKonsultasi(currentMessage.id_konsultasi);
    if (result.status) {
      setTimeout(() => {
        onFinish();
        setLoading(false);
        toast(result.message);
      }, 200);
    } else {
      setTimeout(() => {
        setLoading(false);
        toast(result.message);
      }, 200);
    }
  };

  const findReplyMessage = (id) => {
    return listChats.find((chat) => {
      return chat.id_chat_konsultasi === id;
    });
  };

  useEffect(() => {}, [insert]);

  useEffect(() => {
    if (newMessage !== null) {
      if (newMessage.username === currentMessage.id_user) {
        insertNewMessage(20, newMessage);
      } else {
        setNewMessage(null);
      }
    }
  }, [newMessage]);

  useEffect(() => {
    if (
      currentMessage !== null &&
      currentMessage.respon !== null &&
      currentMessage.respon !== false
    ) {
      if (currentMessage.status === "ONGOING") {
        listenPusher();
      }
      getAllMessage();
      setHideTopic(false);
      if (replyMessage !== null) {
        setReplyMessage(null);
      }
      messageRef.current?.focus();
    }
  }, [newMessage, currentMessage]);

  return (
    <>
      {currentMessage !== null ? (
        <FullScHeight className="w-full relative bg-slate-100 hidden md:flex md:flex-col">
          <Card
            className={`w-full px-6 py-3 rounded-none ${
              !hideTopic ? "border-none" : "border-x-0 border-t-0"
            }`}
          >
            <Row className="gap-3">
              <Avatar src={avatar3} className="my-auto" />
              <Column className="my-auto flex-1">
                <Text className="jakarta font-semibold">
                  {currentMessage !== null
                    ? currentMessage.fullName
                    : "Nama Alumni"}
                </Text>
                <Caption>
                  {currentMessage !== null
                    ? currentMessage.id_user
                    : "NPM Alumni"}
                </Caption>
              </Column>
              <Row className="w-fit my-auto">
                <DropDownMenu className="py-1.5">
                  <Column className="divide-y divide-slate-200/50">
                    <TextButton
                      color="custom"
                      align="left"
                      leadingIcon={<TbUserCircle className="h-5 w-5" />}
                      className="w-full pl-3.5 pr-20 py-3 text-left transition-all duration-200 hover:bg-slate-50 hover:text-indigo-600"
                    >
                      Tentang Alumni
                    </TextButton>
                    <TextButton
                      onClick={() => {
                        setShowDialogDone(true);
                      }}
                      color="custom"
                      align="left"
                      leadingIcon={<TbMessageCircleOff className="h-5 w-5" />}
                      className="w-full pl-3.5 pr-20 py-3 text-left transition-all duration-200 hover:bg-slate-50 hover:text-indigo-600"
                    >
                      Akhiri Konsultasi
                    </TextButton>
                  </Column>
                </DropDownMenu>
              </Row>
            </Row>
          </Card>
          {!hideTopic && (
            <Row className="bg-yellow-200/80 px-6 py-3 gap-2">
              <Caption className="text-gray-700 line-clamp-1 flex-1 my-auto">
                <span className="font-semibold">Topik:</span>{" "}
                {currentMessage.topik}
              </Caption>
              <IconButton
                onClick={() => {
                  setHideTopic(true);
                }}
                color="custom"
                className="p-0.5 my-auto text-gray-500 hover:text-gray-700"
              >
                <TbX className="h-4 w-4" />
              </IconButton>
            </Row>
          )}
          <Column
            className={`flex overflow-y-auto scrollbar-hide ${
              currentMessage.respon !== null && currentMessage.respon !== false
                ? "flex-col-reverse h-full"
                : "h-full items-center"
            }`}
          >
            {currentMessage.status === "COMPLETE" && (
              <Column className="gap-5 mb-8">
                <Card className="self-center pl-4 pr-5 py-2 rounded-full">
                  <Row className="w-fit gap-2">
                    <TbChecks className="h-5 w-5 text-green-500" />
                    <Caption>Sesi konsultasi selesai</Caption>
                  </Row>
                </Card>
                {currentMessage.rating === 0 ? (
                  <Caption className="self-center">
                    Menunggu penilaian dari{" "}
                    <span className="font-medium text-gray-600">
                      {currentMessage.fullName}
                    </span>
                  </Caption>
                ) : (
                  currentMessage.status === "COMPLETE" &&
                  currentMessage.rating !== 0 && (
                    <Column className="gap-2">
                      <Caption className="self-center">
                        <span className="font-medium text-gray-600">
                          {currentMessage.fullName}
                        </span>{" "}
                        memberikan penilaian:
                      </Caption>
                      <Card className="self-center px-4 py-1 rounded-full">
                        <Row className="w-fit gap-2">
                          <Title className="archivo my-auto font-medium">
                            {currentMessage.rating}.0
                          </Title>
                          <Row className="w-fit my-auto">
                            {listRate.map((rate, idx) => {
                              return (
                                <TbStarFilled
                                  key={idx}
                                  className={`h-5 w-5 my-auto ${
                                    currentMessage.rating >= rate.rate
                                      ? "text-amber-400"
                                      : "text-gray-200"
                                  }`}
                                />
                              );
                            })}
                          </Row>
                        </Row>
                      </Card>
                    </Column>
                  )
                )}
              </Column>
            )}
            {currentMessage.respon !== null &&
              currentMessage.respon !== false &&
              listChats.map((chat, idx) => {
                let margin = "mb-5";
                let reply = null;
                if (idx === 0) {
                  margin = "mb-8";
                } else if (idx > 0) {
                  if (chat.from_who === listChats[idx - 1].from_who) {
                    if (idx === listChats.length - 1) {
                      margin = "mb-1 mt-5";
                    } else {
                      margin = "mb-1";
                    }
                  } else {
                    if (idx === listChats.length - 1) {
                      margin = "my-5";
                    } else {
                      margin = "mb-5";
                    }
                  }
                }
                if (chat.replay_to !== null) {
                  reply = findReplyMessage(chat.replay_to) ?? null;
                }
                return (
                  <CKonBubbleMessage
                    key={idx}
                    chat={chat}
                    margin={margin}
                    replyMessage={reply}
                    enableAction={
                      currentMessage.status === "ONGOING" ? true : false
                    }
                    onReply={onChangeReplyMessage}
                    onDelete={onChangeDeleteMessage}
                  />
                );
              })}
            {currentMessage.respon === null ? (
              <Card className="w-full max-w-sm my-auto rounded-xl p-0 shadow-xl shadow-slate-200/50">
                <Column className="items-center gap-8 py-8 px-6">
                  <Row className="-space-x-5 w-fit">
                    <Avatar
                      className="h-14 w-14 border-2 border-white"
                      src={avatar1}
                    />
                    <Avatar
                      className="h-14 w-14 border-2 border-white"
                      src={avatar3}
                    />
                  </Row>
                  <Column className="gap-2">
                    <Headline3 className="text-center">
                      Konfirmasi Konsultasi
                    </Headline3>
                    <Text className="text-center">
                      Konfirmasi kesediaan anda menjadi konselor pada sesi
                      konsultasi bersama{" "}
                      <span className="font-semibold">
                        {currentMessage.fullName}
                      </span>
                    </Text>
                  </Column>
                  <Card className="w-full max-h-32 p-3 border-gray-200/50 bg-slate-50 overflow-y-auto scrollbar-hide">
                    <Caption className="text-gray-700 whitespace-pre-line">
                      <span className="font-semibold">Topik:</span>{" "}
                      {currentMessage.topik}
                    </Caption>
                  </Card>
                </Column>
                <GridCol className="gap-0 divide-x border-t border-gray-200/80 divide-gray-200/80">
                  <TextButton
                    onClick={() => onApprove(10)}
                    color="red"
                    className="w-full py-4 text-base hover:bg-red-50/50"
                  >
                    Tidak Bersedia
                  </TextButton>
                  <TextButton
                    onClick={() => onApprove(20)}
                    color="indigo"
                    className={`w-full py-4 text-base hover:bg-indigo-50/50`}
                  >
                    Mulai Sesi
                  </TextButton>
                </GridCol>
              </Card>
            ) : currentMessage.respon ? (
              <Column className="gap-1.5 my-5">
                <Card className="max-w-xl self-center pl-2 pr-4 py-1.5 rounded-full">
                  <Row className="w-fit gap-2">
                    <TbUserCircle className="h-5 w-5 text-blue-500 my-auto" />
                    <Caption>
                      Bidang Alumni menunjuk Anda sebagai Konselor
                    </Caption>
                  </Row>
                </Card>
                <Card className="max-w-xl self-center pl-2 pr-4 py-1.5 rounded-full">
                  <Row className="w-fit gap-2">
                    <TbChecks className="h-5 w-5 text-green-600 my-auto" />
                    <Caption>
                      Anda menerima sesi konsultasi bersama{" "}
                      {currentMessage.fullName}
                    </Caption>
                  </Row>
                </Card>
                <Card className="max-w-xl self-center pl-2 pr-4 py-1.5 rounded-full">
                  <Row className="w-fit gap-2">
                    <TbCircleDashed className="h-5 w-5 text-blue-500 my-auto" />
                    <Caption>Sesi konsultasi berlangsung</Caption>
                  </Row>
                </Card>
              </Column>
            ) : (
              <Column className="gap-1.5 my-5">
                <Card className="max-w-xl self-center pl-2 pr-4 py-1.5 rounded-full">
                  <Row className="w-fit gap-2">
                    <TbUserCircle className="h-5 w-5 text-blue-500 my-auto" />
                    <Caption>
                      Bidang Alumni menunjuk Anda sebagai Konselor
                    </Caption>
                  </Row>
                </Card>
                <Card className="max-w-xl self-center pl-2 pr-4 py-1.5 rounded-full">
                  <Row className="w-fit gap-2">
                    <TbMessageCircleOff className="h-5 w-5 text-red-600 my-auto" />
                    <Caption>
                      Anda menolak sesi konsultasi bersama{" "}
                      {currentMessage.fullName}
                    </Caption>
                  </Row>
                </Card>
              </Column>
            )}
          </Column>
          {currentMessage.respon !== null &&
          currentMessage.respon !== false &&
          currentMessage.status === "ONGOING" ? (
            <Card
              className={`w-full px-6 gap-3 rounded-none border-t border-x-0 ${
                replyMessage !== null ? "pt-3 pb-7" : "pt-5 pb-7"
              }`}
            >
              {replyMessage !== null && (
                <Row className="gap-4">
                  <Column className="border-l-4 border-l-gray-300 px-4 py-1.5">
                    <Caption className="text-gray-700 font-medium">
                      Membalas{" "}
                      {replyMessage.from_who === 10
                        ? currentMessage.fullName
                        : "diri sendiri"}
                    </Caption>
                    <Caption className="line-clamp-1 whitespace-pre-line">
                      {replyMessage.chat}
                    </Caption>
                  </Column>
                  <IconButton
                    className="my-auto"
                    onClick={() => onChangeReplyMessage(null)}
                  >
                    <TbX className="h-5 w-5" />
                  </IconButton>
                </Row>
              )}
              <Form onSubmit={onSubmitForm}>
                <Row className="gap-3">
                  <TextareaAutosize
                    id="message"
                    ref={messageRef}
                    rows={1}
                    maxRows={6}
                    onKeyDown={enterKeyPressed}
                    className="input w-full bg-slate-100/50 py-3 border border-slate-200/50 rounded-lg scrollbar-hide focus:outline-none"
                    placeholder="Tulis pesan"
                    autoFocus
                  />
                  <IconButton
                    type="submit"
                    color="custom"
                    className="bg-indigo-600 text-white my-auto hover:bg-indigo-700"
                  >
                    <TbSend className="h-6 w-6 rotate-45 -translate-x-0.5" />
                  </IconButton>
                </Row>
              </Form>
            </Card>
          ) : (
            currentMessage.respon === null &&
            currentMessage.respon !== false &&
            currentMessage.status === "PENDING" && (
              <Card className="w-full px-6 py-4 rounded-none border-t border-x-0 border-b-0">
                <Row className="gap-2">
                  <TbInfoCircle className="h-5 w-5 my-auto text-gray-500" />
                  <Caption className="my-auto">
                    Sesi konsultasi akan dimulai saat anda sudah menyetujui
                    sebagai konselor pada sesi konsultasi yang diajukan.
                  </Caption>
                </Row>
              </Card>
            )
          )}
        </FullScHeight>
      ) : (
        <FullScHeight className="w-full bg-slate-50 hidden md:flex md:flex-col">
          <Column className="h-full items-center">
            <Column className="max-w-xl h-fit items-center p-8 gap-5 my-auto">
              <Card className="rounded-3xl p-2">
                <TbMessageCircle className="h-20 w-20 stroke-1.5 text-indigo-600" />
              </Card>
              <Headline3 className="text-center">
                Layanan Konsultasi Alumni
              </Headline3>
              <Text className="text-center">
                Halo Alumni UTY, selamat datang dilayanan konsultasi yang siap
                membantu kamu dengan dibimbing oleh koselor terbaik
              </Text>
              <PrimaryButton
                color="indigo"
                className="pr-5 mt-5"
                pills
                leadingIcon={<TbMessageCircle className="h-5 w-5" />}
              >
                Mulai Konsultasi
              </PrimaryButton>
            </Column>
          </Column>
        </FullScHeight>
      )}
      <AlertDialog
        className="max-w-xs"
        show={showDialog}
        onClose={() => {
          setShowDialog(false);
        }}
      >
        <Column>
          <Column className="px-5 pt-10 pb-5 items-center gap-8">
            <Card
              className={`p-1.5 rounded-2xl bg-red-50/50 border-red-200/50`}
            >
              <TbTrash className="h-14 w-14 text-red-600 stroke-1.5" />
            </Card>
            <Column className="gap-2">
              <Headline3 className="text-center">Hapus Pesan</Headline3>
              <Text className="text-center">
                Pesan akan dihapus pada sesi konsultasi dan mungkin sudah ada
                yang melihat sebelumnya
              </Text>
            </Column>
          </Column>
          <GridCol className="gap-0 divide-x border-t border-gray-200/80 divide-gray-200/80">
            <TextButton
              onClick={() => {
                setShowDialog(false);
              }}
              className="w-full py-4 text-base hover:bg-gray-50/50"
            >
              Batal
            </TextButton>
            <TextButton
              onClick={reqDeleteMessage}
              color="red"
              className={`w-full py-4 text-base hover:bg-red-50/50`}
            >
              Hapus
            </TextButton>
          </GridCol>
        </Column>
      </AlertDialog>
      <AlertDialog
        className="max-w-xs"
        show={showDialogDone}
        onClose={() => {
          setShowDialogDone(false);
        }}
      >
        <Column>
          <Column className="px-5 pt-10 pb-5 items-center gap-8">
            <Card
              className={`p-1.5 rounded-2xl bg-red-50/50 border-red-200/50`}
            >
              <TbMessageCircleOff className="h-14 w-14 text-red-600 stroke-1.5" />
            </Card>
            <Column className="gap-2">
              <Headline3 className="text-center">Akhiri Konsultasi</Headline3>
              <Text className="text-center">
                Konselor dan alumni tidak bisa mengirim pesan setelah sesi
                konsultasi ditutup
              </Text>
            </Column>
          </Column>
          <GridCol className="gap-0 divide-x border-t border-gray-200/80 divide-gray-200/80">
            <TextButton
              onClick={() => {
                setShowDialogDone(false);
              }}
              className="w-full py-4 text-base hover:bg-gray-50/50"
            >
              Batal
            </TextButton>
            <TextButton
              onClick={reqFinishConsultation}
              color="red"
              className={`w-full py-4 text-base hover:bg-red-50/50`}
            >
              Akhiri
            </TextButton>
          </GridCol>
        </Column>
      </AlertDialog>
      <LoadingDialog show={loading} message="Sedang memproses..." />
    </>
  );
};

export default WKonChats;
