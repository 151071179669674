import React, { useEffect, useState } from "react";
import { TbBellFilled, TbMenu2 } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { getNotificationsController } from "../../controllers/global_controllers";
import logosimfora from "../../images/simfora_black.png";
import {
  Appbar,
  AppbarItem,
  AppbarMenu,
  ButtonHover,
  IconButton,
  PrimaryButton,
  Row,
  Subtitle,
} from "../../shared/ui";
import { getCurrentUser } from "../../shared/user";
import {
  admNotificationRoute,
  admRootRoute,
  almConsultationRoute,
  almNotificationProfileRoute,
  almProfileRoute,
  almTracerRoute,
  careerRoute,
  eventRoute,
  landingRoute,
  loginRoute,
  mitNotificationRoute,
  mitRootRoute,
  proRootRoute,
  prodNotificationRoute,
  recognitionRoute,
} from "../../shared/variable";

function AlmAppBar({ onShowSidebar }) {
  const location = useLocation();
  const navigateTo = useNavigate();

  const { name, username, nama_jabatan, kode } = getCurrentUser();

  const [sumNewNotif, setSumNewNotif] = useState(0);
  const [panel, setPanel] = useState(null);

  const getNotifications = async () => {
    if (username) {
      const result = await getNotificationsController();
      if (result.status) {
        const listNotification = [...result.data];
        const unread = listNotification.filter(
          (notif) => notif.is_read === false
        );
        setSumNewNotif(unread.length);
      }
    }
  };

  const changeShowPanel = (value) => {
    setPanel(value);
  };
  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <Appbar
      className={`px-4 z-30 bg-white/90 backdrop-blur-xl border-b border-b-gray-200 lg:px-8 lg:py-5`}
    >
      <Row className={"justify-between"}>
        <IconButton className={"xl:hidden"} onClick={onShowSidebar}>
          <TbMenu2 className={"h-6 w-6"} />
        </IconButton>
        <img
          src={logosimfora}
          className={"cursor-pointer hidden xl:flex h-7 my-auto"}
          onClick={() => navigateTo(landingRoute)}
        />
        <AppbarMenu className={"hidden xl:flex gap-x-3"}>
          <AppbarItem
            id="home"
            title="Beranda"
            className={"text-lg"}
            active={location.pathname === landingRoute}
            onClick={() => navigateTo(landingRoute)}
          />
          <AppbarItem
            id="recognition"
            title="Berita"
            className={"text-lg"}
            active={location.pathname.includes(recognitionRoute)}
            onClick={() => navigateTo(recognitionRoute)}
          />
          <AppbarItem
            id="event"
            title="Event"
            className={"text-lg"}
            active={location.pathname.includes(eventRoute)}
            onClick={() => navigateTo(eventRoute)}
          />
          {username && (
            <AppbarItem
              id="tracer"
              title="Tracer Study"
              className={"text-lg"}
              active={location.pathname.includes(almTracerRoute)}
              onClick={() => navigateTo(almTracerRoute)}
            />
          )}
          {(username === "5190411438" || username === "5200411340") && (
            <AppbarItem
              id="lowongan"
              title="Lowongan Pekerjaan"
              className={"text-lg"}
              active={location.pathname.includes(careerRoute)}
              onClick={() => navigateTo(careerRoute)}
            />
          )}
          {username && (
            <AppbarItem
              id="consultation"
              title="Konsultasi"
              className={"text-lg"}
              active={location.pathname.includes(almConsultationRoute)}
              onClick={() => navigateTo(almConsultationRoute)}
            />
          )}
        </AppbarMenu>
        {username ? (
          <Row className={"w-fit gap-x-3 sm:gap-x-5 my-auto"}>
            <ButtonHover className={"my-auto"}>
              <div className={"relative"}>
                {sumNewNotif > 0 && (
                  <span className={"absolute top-2 right-2 flex h-2.5 w-2.5"}>
                    <span
                      className={
                        "animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"
                      }
                    />
                    <span
                      className={
                        "relative inline-flex rounded-full h-2.5 w-2.5 bg-red-500 border-[1.5px] border-white"
                      }
                    />
                  </span>
                )}
                <IconButton
                  color="custom"
                  className={
                    "text-gray-800 hover:text-indigo-600 hover:bg-gray-50"
                  }
                  onClick={
                    username === "admintracer"
                      ? () => navigateTo(admNotificationRoute)
                      : () =>
                          navigateTo(almNotificationProfileRoute) ||
                          nama_jabatan === "Kepala Program Studi"
                            ? navigateTo(prodNotificationRoute)
                            : navigateTo(almNotificationProfileRoute) ||
                              kode === 5
                            ? navigateTo(mitNotificationRoute)
                            : navigateTo(almNotificationProfileRoute)
                  }
                >
                  <TbBellFilled className={"h-6 w-6"} />
                </IconButton>
              </div>
            </ButtonHover>
            <ButtonHover className="my-auto">
              <div
                className={
                  "flex flex-col w-8 h-8 bg-indigo-600 rounded-full items-center"
                }
                onClick={
                  username === "admintracer"
                    ? () => navigateTo(admRootRoute)
                    : () =>
                        navigateTo(almProfileRoute) ||
                        nama_jabatan === "Kepala Program Studi"
                          ? navigateTo(proRootRoute)
                          : navigateTo(almProfileRoute) || kode === 5
                          ? navigateTo(mitRootRoute)
                          : navigateTo(almProfileRoute)
                }
              >
                <Subtitle className={"text-white font-semibold my-auto"}>
                  {name.substring(0, 1)}
                </Subtitle>
              </div>
            </ButtonHover>
          </Row>
        ) : (
          <ButtonHover>
            <PrimaryButton
              pills
              color="indigo"
              className={"px-6"}
              onClick={() => navigateTo(loginRoute)}
            >
              Masuk
            </PrimaryButton>
          </ButtonHover>
        )}
      </Row>
    </Appbar>
  );
}

export default AlmAppBar;
