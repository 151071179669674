import React, { useEffect, useRef, useState } from "react";
import {
  AlertDialog,
  ButtonHover,
  Caption,
  Card,
  Column,
  Form,
  GridCol,
  Headline1,
  Headline3,
  IconButton,
  LargeHeadline,
  LoadingDialog,
  OutlineTextArea,
  Overline,
  Row,
  Subtitle,
  Text,
  TextButton,
  Title,
} from "../shared/ui";
import {
  TbChecks,
  TbCircleDashed,
  TbDots,
  TbHistoryToggle,
  TbMessageCircle,
  TbMessageCircleOff,
  TbSend,
  TbStarFilled,
  TbTrash,
  TbX,
} from "react-icons/tb";
import CAlmBubbleMessage from "./cards/calm_bubble_message";
import {
  deleteChatKonsultasi,
  getChatKonsultasi,
  rateKonsultasi,
  sendChatKonsultasi,
} from "../controllers/apicontrollers";
import { toast } from "react-hot-toast";
import { getCurrentUser } from "../shared/user";
import Randomstring from "randomstring";

const WAlmChats = ({
  currentMessage = null,
  channel = null,
  onUpdateMessage,
}) => {
  const messageRef = useRef();
  const [insert, setInsert] = useState(null);
  const [listChats, setListChats] = useState([]);
  const [newMessage, setNewMessage] = useState(null);
  const [replyMessage, setReplyMessage] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [getMessage, setGetMessage] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const listRate = [
    { rate: 1, label: "Sangat Tidak Puas" },
    { rate: 2, label: "Tidak Puas" },
    { rate: 3, label: "Cukup Puas" },
    { rate: 4, label: "Puas" },
    { rate: 5, label: "Sangat Puas" },
  ];
  const [currentRate, setCurrentRate] = useState({
    rate: 0,
    label: "Pilih penilaian",
  });
  const [currentRateTemp, setCurrentRateTemp] = useState(0);

  const onSubmitForm = (event) => {
    event.preventDefault();
    const message = event.target.message.value;
    if (message.length > 0) {
      saveMessage(message);
    }
  };

  const enterKeyPressed = (event) => {
    if (event.keyCode == 13 && event.shiftKey == false) {
      event.preventDefault();
      const message = event.target.value;
      if (message.length > 0) {
        saveMessage(message);
      }
    }
  };

  const saveMessage = async (message) => {
    const result = await sendChatKonsultasi(
      currentMessage.id_konsultasi,
      message,
      replyMessage !== null ? replyMessage.id_chat_konsultasi : null
    );
    if (result.status) {
      insertNewMessage(10, result.data);
    }
  };

  const insertNewMessage = (type, data) => {
    const listChatsTemp = listChats;
    const newMessage = {
      chat: data.chat,
      created_at: data.created_at,
      from_who: data.from_who,
      id_chat_konsultasi: data.id_chat_konsultasi,
      id_konsultasi: currentMessage.id_konsultasi,
      replay_to: data.replay_to,
      updated_at: data.created_at,
    };
    listChatsTemp.unshift(newMessage);
    setListChats(listChatsTemp);
    setInsert(Randomstring.generate(6));
    onUpdateMessage({
      id: currentMessage.id_konsultasi,
      message: data.chat,
      date: data.created_at,
    });
    if (type === 10) {
      messageRef.current.value = "";
      if (replyMessage !== null) {
        setReplyMessage(null);
      }
    } else {
      setNewMessage(null);
    }
  };

  const listenPusher = () => {
    const { username, kode } = getCurrentUser();
    channel.bind(`${kode}_${username}`, function (data) {
      setNewMessage(data);
    });
  };

  const getAllMessage = async () => {
    const result = await getChatKonsultasi(currentMessage.id_konsultasi);
    if (result.status) {
      setListChats(result.data);
      setGetMessage(true);
    }
  };

  const onChangeReplyMessage = (message) => {
    setReplyMessage(message);
    messageRef.current?.focus();
  };

  const onChangeDeleteMessage = async (message) => {
    setDeleteMessage(message);
    setShowDialog(true);
  };

  const reqDeleteMessage = async () => {
    setShowDialog(false);
    setLoading(true);
    const result = await deleteChatKonsultasi(deleteMessage.id_chat_konsultasi);
    if (result.status) {
      setTimeout(() => {
        const chatTemp = listChats.filter((chat) => chat !== deleteMessage);
        setListChats(chatTemp);
        setDeleteMessage(null);
        setLoading(false);
        toast(result.message);
      }, 200);
    } else {
      setTimeout(() => {
        setLoading(false);
        toast(result.message);
      }, 200);
    }
  };

  const reqRateConsultation = async (rate) => {
    setCurrentRate({ rate: rate.rate, label: rate.label });
    const result = await rateKonsultasi(
      currentMessage.id_konsultasi,
      rate.rate
    );
    if (result.status) {
      setTimeout(() => {
        toast(result.message);
      }, 200);
    } else {
      setTimeout(() => {
        toast(result.message);
      }, 200);
    }
  };

  const findReplyMessage = (id) => {
    return listChats.find((chat) => {
      return chat.id_chat_konsultasi === id;
    });
  };

  useEffect(() => {}, [insert]);

  useEffect(() => {
    if (newMessage !== null) {
      if (newMessage.username === currentMessage.id_konselor) {
        insertNewMessage(20, newMessage);
      } else {
        setNewMessage(null);
      }
    }
  }, [newMessage]);

  useEffect(() => {
    if (
      currentMessage !== null &&
      currentMessage.respon !== null &&
      currentMessage.respon !== false
    ) {
      if (currentMessage.status === "ONGOING") {
        listenPusher();
      }
      getAllMessage();
      if (currentMessage.rating !== 0) {
        setCurrentRate(listRate[currentMessage.rating - 1]);
      }
      if (replyMessage !== null) {
        setReplyMessage(null);
      }
      messageRef.current?.focus();
    }
  }, [currentMessage]);

  return (
    <>
      {currentMessage !== null ? (
        <Column className="w-full h-[85vh] bg-slate-50 relative hidden md:flex">
          <Column className={`w-full px-4 py-4 bg-white border-b-[1px] z-10`}>
            <Row className="gap-x-3 items-center">
              <div className="flex h-10 w-10 rounded-full justify-center items-center bg-gray-700">
                <Text className="text-white uppercase font-medium text-center">
                  {currentMessage.nama_konselor !== null
                    ? currentMessage.nama_konselor.substring(0, 2)
                    : currentMessage.subject !== null
                    ? currentMessage.subject.substring(0, 2)
                    : "SK"}
                </Text>
              </div>
              <Column className="my-auto flex-1">
                <Text className="font-manrope font-semibold uppercase">
                  {currentMessage.id_konselor !== null
                    ? currentMessage.nama_konselor
                    : currentMessage.subject ?? "Subject Konsultasi"}
                </Text>
                <Caption className="line-clamp-1">
                  {currentMessage.subject !== null
                    ? currentMessage.subject ?? "Subject Konsultasi"
                    : currentMessage.acc_bidal === "REJECTED"
                    ? "Permintaan Konsultasi Ditolak"
                    : "Permintaan Konsultasi Terkirim"}
                </Caption>
              </Column>
              {/* <Row className='w-fit gap-x-2'>
                            {currentMessage.status === 'ONGOING' && <IconButton bordered color='red'>
                                <TbMessageCircleOff className='h-5 w-5' />
                            </IconButton>}
                            <IconButton bordered>
                                <TbDots className='h-5 w-5' />
                            </IconButton>
                        </Row> */}
            </Row>
          </Column>
          <Column
            className={`flex overflow-y-auto scrollbar-hide ${
              currentMessage.respon !== null && currentMessage.respon !== false
                ? "flex-col-reverse h-full"
                : "h-full items-center"
            }`}
          >
            {currentMessage.status === "COMPLETE" && (
              <Column className="gap-y-2 pb-8">
                <Card className="max-w-xl self-center pl-3 pr-5 py-2.5 rounded-full">
                  <Row className="w-fit gap-x-2">
                    <TbChecks className="h-5 w-5 text-green-600 my-auto" />
                    <Caption>Sesi konsultasi selesai</Caption>
                  </Row>
                </Card>
                {currentRate.rate === 0 ? (
                  <Column className="items-center gap-y-2">
                    <Caption className="self-center">
                      Berikan penilaian Anda untuk sesi konsultasi bersama{" "}
                      <span className="font-medium text-gray-600">
                        {currentMessage.nama_konselor}
                      </span>
                    </Caption>
                    <Card className="self-center px-4 py-1 rounded-full shadow-none">
                      <Row className="w-fit gap-2">
                        <Title className="font-inter my-auto font-medium">
                          {currentRate.rate}.0
                        </Title>
                        <Row className="w-fit my-auto">
                          {listRate.map((rate, idx) => {
                            return (
                              <TbStarFilled
                                onMouseEnter={() => {
                                  setCurrentRateTemp(rate.rate);
                                }}
                                onMouseLeave={() => {
                                  setCurrentRateTemp(0);
                                }}
                                onClick={() => reqRateConsultation(rate)}
                                key={idx}
                                className={`h-5 w-5 cursor-pointer ${
                                  currentRate.rate >= rate.rate
                                    ? "text-amber-400"
                                    : currentRateTemp >= rate.rate
                                    ? "text-amber-400"
                                    : "text-gray-300"
                                }`}
                              />
                            );
                          })}
                        </Row>
                      </Row>
                    </Card>
                  </Column>
                ) : (
                  <Column className="gap-2">
                    <Caption className="self-center">
                      <span className="font-medium text-gray-600">Anda</span>{" "}
                      memberikan penilaian sesi konsultasi:
                    </Caption>
                    <Card className="self-center px-4 py-1 rounded-full shadow-none">
                      <Row className="w-fit gap-2">
                        <Title className="font-inter my-auto font-medium">
                          {currentRate.rate}.0
                        </Title>
                        <Row className="w-fit my-auto">
                          {listRate.map((rate, idx) => {
                            return (
                              <TbStarFilled
                                key={idx}
                                className={`h-5 w-5 my-auto ${
                                  currentRate.rate >= rate.rate
                                    ? "text-amber-400"
                                    : "text-gray-200"
                                }`}
                              />
                            );
                          })}
                        </Row>
                      </Row>
                    </Card>
                  </Column>
                )}
              </Column>
            )}
            {currentMessage.respon !== null &&
              currentMessage.respon !== false &&
              listChats.map((chat, idx) => {
                let margin = "mb-5";
                let reply = null;
                if (idx === 0) {
                  margin = "mb-4";
                } else if (idx > 0) {
                  if (chat.from_who == listChats[idx - 1].from_who) {
                    if (idx === listChats.length - 1) {
                      margin = "mb-0.5 mt-5";
                    } else {
                      margin = "mb-0.5";
                    }
                  } else {
                    if (idx === listChats.length - 1) {
                      margin = "my-5";
                    } else {
                      margin = "mb-5";
                    }
                  }
                }
                if (chat.replay_to !== null) {
                  reply = findReplyMessage(chat.replay_to) ?? null;
                }
                return (
                  <CAlmBubbleMessage
                    key={idx}
                    chat={chat}
                    margin={margin}
                    replyMessage={reply}
                    enableAction={
                      currentMessage.status === "ONGOING" ? true : false
                    }
                    onReply={onChangeReplyMessage}
                    onDelete={onChangeDeleteMessage}
                  />
                );
              })}
            {currentMessage.acc_bidal === "ACCEPTED" ? (
              <Column
                className={`gap-y-2 ${
                  currentMessage.respon !== null &&
                  currentMessage.respon !== false
                    ? "pt-5 pb-5"
                    : "my-5"
                }`}
              >
                <Card className="max-w-xl self-center pl-3 pr-5 py-2.5 rounded-full">
                  <Row className="w-fit gap-x-2">
                    <TbSend className="h-5 w-5 text-blue-600 my-auto rotate-12" />
                    <Caption>
                      Permintaan Konsultasi Anda berhasil terkirim
                    </Caption>
                  </Row>
                </Card>
                <Card className="max-w-xl self-center pl-3 pr-5 py-2.5 rounded-full">
                  <Row className="w-fit gap-x-2">
                    <TbChecks className="h-5 w-5 text-green-600 my-auto" />
                    <Caption>
                      Permintaan Konsultasi Anda diterima Bidang Alumni
                    </Caption>
                  </Row>
                </Card>
                {currentMessage.respon === null ? (
                  <Card className="max-w-xl self-center pl-3 pr-5 py-2.5 rounded-full">
                    <Row className="w-fit gap-x-2">
                      <TbHistoryToggle className="h-5 w-5 text-orange-600 my-auto" />
                      <Caption>
                        Menunggu Konselor memulai Sesi Konsultasi
                      </Caption>
                    </Row>
                  </Card>
                ) : currentMessage.respon ? (
                  <Card className="max-w-xl self-center pl-3 pr-5 py-2.5 rounded-full">
                    <Row className="w-fit gap-x-2">
                      <TbCircleDashed className="h-5 w-5 text-blue-600 my-auto" />
                      <Caption>Sesi Konsultasi Berlangsung</Caption>
                    </Row>
                  </Card>
                ) : (
                  <Card className="max-w-xl self-center pl-3 pr-5 py-2.5 rounded-full">
                    <Row className="w-fit gap-x-2">
                      <TbMessageCircleOff className="h-5 w-5 text-red-600 my-auto" />
                      <Caption>Konselor tidak menerima Sesi Konsultasi</Caption>
                    </Row>
                  </Card>
                )}
              </Column>
            ) : currentMessage.acc_bidal === "REJECTED" ? (
              <Column className="gap-y-2 my-5">
                <Card className="max-w-xl self-center pl-3 pr-5 py-2.5 rounded-full">
                  <Row className="w-fit gap-x-2">
                    <TbSend className="h-5 w-5 text-blue-600 my-auto rotate-12" />
                    <Caption>
                      Permintaan Konsultasi Anda berhasil terkirim
                    </Caption>
                  </Row>
                </Card>
                {currentMessage.respon === null ? (
                  <Card className="max-w-xl self-center pl-3 pr-5 py-2.5 rounded-full">
                    <Row className="w-fit gap-x-2">
                      <TbHistoryToggle className="h-5 w-5 text-orange-600 my-auto" />
                      <Caption>
                        Permintaan Konsultasi Anda akan diverifikasi Bidang
                        Alumni
                      </Caption>
                    </Row>
                  </Card>
                ) : (
                  <Card className="max-w-xl self-center pl-3 pr-5 py-2.5 rounded-full">
                    <Row className="w-fit gap-x-2">
                      <TbChecks className="h-5 w-5 text-green-600 my-auto" />
                      <Caption>
                        Permintaan Konsultasi Anda diterima Bidang Alumni
                      </Caption>
                    </Row>
                  </Card>
                )}
                {currentMessage.respon === null ? (
                  <Card className="max-w-xl self-center pl-3 pr-5 py-2.5 rounded-full">
                    <Row className="w-fit gap-x-2">
                      <TbMessageCircleOff className="h-5 w-5 text-red-600 my-auto" />
                      <Caption>
                        Permintaan Konsultasi Anda ditolak Bidang Alumni
                      </Caption>
                    </Row>
                  </Card>
                ) : (
                  <Card className="max-w-xl self-center pl-3 pr-5 py-2.5 rounded-full">
                    <Row className="w-fit gap-x-2">
                      <TbMessageCircleOff className="h-5 w-5 text-red-600 my-auto" />
                      <Caption>Konselor tidak menerima Sesi Konsultasi</Caption>
                    </Row>
                  </Card>
                )}
              </Column>
            ) : (
              <Column className="gap-y-2 my-5">
                <Card className="max-w-xl self-center pl-3 pr-5 py-2.5 rounded-full">
                  <Row className="w-fit gap-x-2">
                    <TbSend className="h-5 w-5 text-blue-600 my-auto rotate-12" />
                    <Caption>
                      Permintaan Konsultasi Anda berhasil terkirim
                    </Caption>
                  </Row>
                </Card>
                <Card className="max-w-xl self-center pl-3 pr-5 py-2.5 rounded-full">
                  <Row className="w-fit gap-x-2">
                    <TbHistoryToggle className="h-5 w-5 text-orange-600 my-auto" />
                    <Caption>
                      Permintaan Konsultasi Anda akan diverifikasi Bidang Alumni
                    </Caption>
                  </Row>
                </Card>
              </Column>
            )}
          </Column>
          <Column className="w-full bg-white border-t-[1px] px-4 py-4 gap-y-4 z-10">
            {replyMessage !== null && (
              <Row className="gap-x-4">
                <Column className="border-l-[3px] border-l-yellow-500 px-5 rounded-l-sm">
                  <Overline className="font-medium line-clamp-1 text-yellow-600">
                    Membalas{" "}
                    {replyMessage.from_who === 20
                      ? currentMessage.nama_konselor
                      : "Pesan Saya"}
                  </Overline>
                  <Caption className="line-clamp-1 whitespace-pre-line">
                    {replyMessage.chat}
                  </Caption>
                </Column>
                <IconButton
                  onClick={() => onChangeReplyMessage(null)}
                  className="my-auto"
                >
                  <TbX className="h-5 w-5 text-red-600" />
                </IconButton>
              </Row>
            )}
            <Form onSubmit={onSubmitForm}>
              <Row className="gap-x-3 items-end">
                <OutlineTextArea
                  id="message"
                  refs={messageRef}
                  inputWidth="w-full"
                  className="rounded-xl"
                  maxRows={6}
                  disabled={currentMessage.status !== "ONGOING"}
                  bgColor="bg-slate-50"
                  onKeyDown={enterKeyPressed}
                  placeholder="Tulis pesan"
                  autoFocus={
                    currentMessage.acc_bidal === "ACCEPTED" &&
                    currentMessage.status === "ONGOING"
                  }
                />
                {currentMessage.acc_bidal === "ACCEPTED" &&
                currentMessage.status === "ONGOING" ? (
                  <ButtonHover>
                    <IconButton
                      type="submit"
                      color="custom"
                      className="bg-gradient-to-br from-blue-600  to-indigo-600 text-white -translate-y-[0.17rem] hover:bg-indigo-700"
                    >
                      <TbSend className="h-6 w-6 rotate-45 -translate-x-0.5 stroke-1.5" />
                    </IconButton>
                  </ButtonHover>
                ) : (
                  <IconButton disabled className="-translate-y-[0.17rem]">
                    <TbSend className="h-6 w-6 rotate-45 -translate-x-0.5 stroke-1.5" />
                  </IconButton>
                )}
              </Row>
            </Form>
          </Column>
        </Column>
      ) : (
        <Column className="w-full h-[85vh] hidden md:flex">
          <Column className="h-full p-16 gap-y-8">
            <Card className="rounded-[1.3rem] p-2.5 bg-indigo-50 border-indigo-300">
              <TbMessageCircle className="h-14 w-14 stroke-1.5 text-indigo-600" />
            </Card>
            <Column className="gap-y-3">
              <Headline1 className="font-semibold">Konsultasi Karier</Headline1>
              <Subtitle className="font-normal font-inter">
                Universitas Teknologi Yogyakarta berkomitmen untuk mengantarkan
                alumninya sukses dalam dunia karier yang mereka pilih. Alumni
                UTY yang menemui kendala dan berbagai permasalahan dalam karier
                silakan menghubungi kami. Selain karier, kami juga memfasilitasi
                alumni untuk berkonsultasi mengenai studi lanjut dan wirausaha.
                Alumni UTY akan melakukan sesi konsulasi secara gratis dengan
                konselor terpilih.
              </Subtitle>
            </Column>
            <Caption className="mt-auto">
              Segala konten layanan Konsultasi Karir telah diatur dalam
              Kebijakan Privasi dan Ketentuan Pengguna.
            </Caption>
          </Column>
        </Column>
      )}
      <AlertDialog
        className="max-w-xs"
        show={showDialog}
        onClose={() => {
          setShowDialog(false);
        }}
      >
        <Column>
          <Column className="px-5 pt-10 pb-5 items-center gap-8">
            <Card
              className={`p-1.5 rounded-2xl bg-red-50/50 border-red-200/50`}
            >
              <TbTrash className="h-14 w-14 text-red-600 stroke-1.5" />
            </Card>
            <Column className="gap-2">
              <Headline3 className="text-center">Hapus Pesan</Headline3>
              <Text className="text-center">
                Pesan akan dihapus pada sesi konsultasi dan mungkin sudah ada
                yang melihat sebelumnya
              </Text>
            </Column>
          </Column>
          <GridCol className="gap-0 divide-x border-t border-gray-200 divide-gray-200">
            <TextButton
              onClick={() => {
                setShowDialog(false);
              }}
              className="w-full py-4 text-base hover:bg-gray-50/50"
            >
              Batal
            </TextButton>
            <TextButton
              onClick={reqDeleteMessage}
              color="red"
              className={`w-full py-4 text-base hover:bg-red-50/50`}
            >
              Hapus
            </TextButton>
          </GridCol>
        </Column>
      </AlertDialog>
      <LoadingDialog show={loading} message="Sedang memproses..." />
    </>
  );
};

export default WAlmChats;
