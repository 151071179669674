import React from "react";
import {
  TbMessage,
  TbMessageCancel,
  TbMessageCheck,
  TbMessagePlus,
  TbMessageSearch,
} from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ButtonHover,
  Column,
  PrimaryButton,
  Sidebar,
  SidebarHeader,
  SidebarMenu,
  TextButton,
  Title,
} from "../../shared/ui";
import {
  almConsultationRoute,
  almCreateConsultationRoute,
  almRejectedConsultationRoute,
  almWaitingConsultationRoute,
} from "../../shared/variable";

function AlmConsultationSidebar({ showOnMobile }) {
  const location = useLocation();
  const navigateTo = useNavigate();

  return (
    <Sidebar showOnMobile={"0"} className={"z-20"}>
      <SidebarMenu className={"p-4 gap-y-3.5 pt-24 pb-36"}>
        <SidebarHeader className={"px-6"}>
          <Column className={"gap-y-5 py-3 items-center"}>
            <div className={"bg-indigo-50 rounded-full p-5"}>
              <TbMessage className={"h-14 w-14 stroke-1.5 text-indigo-600"} />
            </div>
            <Title className={"line-clamp-2 text-center"}>
              Konsultasi Karir
            </Title>
          </Column>
        </SidebarHeader>
        <TextButton
          align="left"
          color="custom"
          leadingIcon={<TbMessageCheck className={"h-6 w-6"} />}
          className={`w-full px-6 py-2.5 rounded-xl transition-all duration-200 ${
            location.pathname === almConsultationRoute &&
            "text-indigo-600 bg-gray-50"
          } hover:text-indigo-600 hover:bg-gray-50`}
          onClick={() => navigateTo(almConsultationRoute)}
        >
          Konsultasi Diterima
        </TextButton>
        <TextButton
          align="left"
          color="custom"
          leadingIcon={<TbMessageSearch className={"h-6 w-6"} />}
          className={`w-full px-6 py-2.5 rounded-xl transition-all duration-200 ${
            location.pathname === almWaitingConsultationRoute &&
            "text-indigo-600 bg-gray-50"
          } hover:text-indigo-600 hover:bg-gray-50`}
          onClick={() => navigateTo(almWaitingConsultationRoute)}
        >
          Menunggu Verifikasi
        </TextButton>
        <TextButton
          align="left"
          color="custom"
          leadingIcon={<TbMessageCancel className={"h-6 w-6"} />}
          className={`w-full px-6 py-2.5 rounded-xl transition-all duration-200 ${
            location.pathname === almRejectedConsultationRoute &&
            "text-indigo-600 bg-gray-50"
          } hover:text-indigo-600 hover:bg-gray-50`}
          onClick={() => navigateTo(almRejectedConsultationRoute)}
        >
          Konsultasi Ditolak
        </TextButton>
      </SidebarMenu>
      <Column
        className={
          "absolute bottom-0 inset-x-0 p-4 bg-white border-t border-gray-200"
        }
      >
        <ButtonHover className={"w-full"}>
          <PrimaryButton
            pills
            color="indigo"
            className={"w-full"}
            leadingIcon={<TbMessagePlus className={"h-5 w-5 my-auto"} />}
            onClick={() => navigateTo(almCreateConsultationRoute)}
          >
            Konsultasi Baru
          </PrimaryButton>
        </ButtonHover>
      </Column>
    </Sidebar>
  );
}

export default AlmConsultationSidebar;
