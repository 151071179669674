import React, { useState } from "react";
import {
  TbCircleArrowDown,
  TbCircleArrowUp,
  TbCircleArrowUpRight,
} from "react-icons/tb";
import { useRecoilState } from "recoil";
import {
  gInitCityCareer,
  gInitProvCareer,
  gInitTimeCareer,
  gInitTypeCareer,
} from "../../atoms";
import {
  AlertDialog,
  Column,
  GridCol,
  Headline3,
  Subtitle,
  Text,
  TextButton,
} from "../../shared/ui";

function WFilterCareer({
  action,
  masters,
  types,
  times,
  provinces,
  citys,
  onChangeFilter,
}) {
  const [initType, setInitType] = useRecoilState(gInitTypeCareer);
  const [initTimePost, setInitTimePost] = useRecoilState(gInitTimeCareer);
  const [initProvince, setInitProvince] = useRecoilState(gInitProvCareer);
  const [initCity, setInitCity] = useRecoilState(gInitCityCareer);
  const [opsiCity, setOpsiCity] = useState(citys);
  const [showDialogProv, setShowDialogProv] = useState(false);
  const [showDialogCity, setShowDialogCity] = useState(false);
  const [limitProv, setLimitProv] = useState(6);
  const [limitCity, setLimitCity] = useState(6);

  const onChangeType = (type) => {
    setInitType(type);
    onChangeFilter(10, type);
  };

  const onChangeTimePost = (time) => {
    setInitTimePost(time);
    onChangeFilter(20, time);
  };

  const onChangeProvince = (prov) => {
    if (showDialogProv) {
      setShowDialogProv(false);
    }
    const findProv =
      masters.filter((province) => province.prov_id === prov.value) ?? [];
    if (findProv.length > 0) {
      const newCity = [];
      findProv[0].kota.map((city) => {
        const opsi = {
          label: city.kota_nama,
          value: city.kota_id,
        };
        newCity.push(opsi);
      });
      newCity.sort((a, b) => (a.label > b.label ? 1 : -1));
      newCity.unshift({
        label: "Semua Kab/Kota",
        value: null,
      });
      if (initCity.value !== null) {
        setInitCity({ label: "Semua Kab/Kota", value: null });
      }
      setOpsiCity(newCity);
    } else {
      const newCity = [
        {
          label: "Semua Kab/Kota",
          value: null,
        },
      ];
      setOpsiCity(newCity);
      setInitCity({ label: "Semua Kab/Kota", value: null });
      onChangeFilter(40, { label: "Semua Kab/Kota", value: null });
    }
    if (limitProv > 6) {
      setLimitProv(6);
    }
    if (limitCity > 6) {
      setLimitCity(6);
    }
    setInitProvince(prov);
    onChangeFilter(30, prov);
  };

  const onChangeCity = (city) => {
    if (showDialogCity) {
      setShowDialogCity(false);
    }
    setInitCity(city);
    onChangeFilter(40, city);
  };

  const onChangeLimit = (action) => {
    if (action === 10) {
      if (limitProv < 12) {
        setLimitProv(12);
      } else {
        setShowDialogProv(true);
      }
    } else if (action === 20) {
      if (limitCity < 12) {
        setLimitCity(12);
      } else {
        setShowDialogCity(true);
      }
    } else {
      setLimitCity(6);
    }
  };

  return (
    <>
      {action === 10 ? (
        <Column className={"gap-y-8"}>
          <Column className={"gap-y-3"}>
            <Subtitle className={"font-semibold"}>Tipe lowongan</Subtitle>
            {types.map((type, idx) => {
              return (
                <TextButton
                  key={idx}
                  className={`justify-between font-inter font-medium text-gray-800 bg-white px-4 py-2 border border-gray-200 rounded-full transition-all duration-200 ${
                    type.value === initType.value &&
                    "text-indigo-600 bg-gray-50 border-indigo-300"
                  } hover:border-indigo-300 hover:text-indigo-600`}
                  onClick={() => onChangeType(type)}
                >
                  {type.label}
                </TextButton>
              );
            })}
          </Column>
          <Column className={"gap-y-3"}>
            <Subtitle className={"font-semibold"}>Waktu post</Subtitle>
            {times.map((time, idx) => {
              return (
                <TextButton
                  key={idx}
                  className={`justify-between font-inter font-medium text-gray-800 bg-white px-4 py-2 border border-gray-200 rounded-full transition-all duration-200 ${
                    time.value === initTimePost.value &&
                    "text-indigo-600 bg-gray-50 border-indigo-300"
                  } hover:border-indigo-300 hover:text-indigo-600`}
                  onClick={() => onChangeTimePost(time)}
                >
                  {time.label}
                </TextButton>
              );
            })}
          </Column>
        </Column>
      ) : (
        <Column className={"gap-y-8"}>
          <Column className={"gap-y-3"}>
            <Subtitle className={"font-semibold"}>Provinsi</Subtitle>
            {provinces
              .filter((province, idx) => idx < limitProv)
              .map((prov, idx) => {
                return (
                  <TextButton
                    key={idx}
                    className={`justify-between font-inter font-medium text-gray-800 bg-white px-4 py-2 border border-gray-200 rounded-full transition-all duration-200 ${
                      prov.value === initProvince.value &&
                      "text-indigo-600 bg-gray-50 border-indigo-300"
                    } hover:border-indigo-300 hover:text-indigo-600`}
                    onClick={() => onChangeProvince(prov)}
                  >
                    {prov.label}
                  </TextButton>
                );
              })}
            <TextButton
              className={"px-4 hover:text-indigo-600"}
              trailingIcon={
                limitProv < 12 ? (
                  <TbCircleArrowDown className={"h-5 w-5 my-auto"} />
                ) : (
                  <TbCircleArrowUpRight className={"h-5 w-5 my-auto"} />
                )
              }
              onClick={() => onChangeLimit(10)}
            >
              Lainnya
            </TextButton>
          </Column>
          <Column className={"gap-y-3"}>
            <Subtitle className={"font-semibold"}>Kabupaten/kota</Subtitle>
            {opsiCity
              .filter((city, idx) => idx < limitCity)
              .map((city, idx) => {
                return (
                  <TextButton
                    key={idx}
                    className={`justify-between font-inter font-medium text-gray-800 bg-white px-4 py-2 border border-gray-200 rounded-full transition-all duration-200 ${
                      city.value === initCity.value &&
                      "text-indigo-600 bg-gray-50 border-indigo-300"
                    } hover:border-indigo-300 hover:text-indigo-600`}
                    onClick={() => onChangeCity(city)}
                  >
                    {city.label}
                  </TextButton>
                );
              })}
            {initProvince.value !== null && (
              <TextButton
                className={"px-4 hover:text-indigo-600"}
                trailingIcon={
                  limitCity < 12 ? (
                    <TbCircleArrowDown className={"h-5 w-5 my-auto"} />
                  ) : opsiCity.length < limitCity ? (
                    <TbCircleArrowUp className={"h-5 w-5 my-auto"} />
                  ) : (
                    <TbCircleArrowUpRight className={"h-5 w-5 my-auto"} />
                  )
                }
                onClick={() =>
                  onChangeLimit(opsiCity.length < limitCity ? 30 : 20)
                }
              >
                {opsiCity.length < limitCity ? "Lebih sedikit" : "Lainnya"}
              </TextButton>
            )}
          </Column>
        </Column>
      )}
      <AlertDialog
        show={showDialogProv}
        className={"max-w-6xl"}
        onClose={() => setShowDialogProv(false)}
      >
        <Column className={"p-8 gap-y-5"}>
          <Column className={"gap-y-2"}>
            <Headline3>Provinsi</Headline3>
            <Text>Pilih salah satu provinsi berikut:</Text>
          </Column>
          <GridCol className={"grid-cols-4 gap-x-4 gap-y-3"}>
            {provinces.map((prov, idx) => {
              return (
                <TextButton
                  key={idx}
                  className={`w-full justify-between font-inter font-medium text-gray-800 px-4 py-2 border border-gray-200 rounded-full transition-all duration-200 ${
                    prov.value === initProvince.value &&
                    "text-indigo-600 bg-gray-50"
                  } hover:border-indigo-300 hover:text-indigo-600`}
                  onClick={() => onChangeProvince(prov)}
                >
                  {prov.label}
                </TextButton>
              );
            })}
          </GridCol>
        </Column>
      </AlertDialog>
      <AlertDialog
        show={showDialogCity}
        className={"max-w-6xl"}
        onClose={() => setShowDialogCity(false)}
      >
        <Column className={"p-8 gap-y-5"}>
          <Column className={"gap-y-2"}>
            <Headline3>Kabupaten/Kota</Headline3>
            <Text>Pilih salah satu kabupaten/kota berikut:</Text>
          </Column>
          <GridCol className={"grid-cols-4 gap-x-4 gap-y-3"}>
            {opsiCity.map((city, idx) => {
              return (
                <TextButton
                  key={idx}
                  className={`w-full justify-between font-inter font-medium text-gray-800 px-4 py-2 border border-gray-200 rounded-full transition-all duration-200 ${
                    city.value === initCity.value &&
                    "text-indigo-600 bg-gray-50"
                  } hover:border-indigo-300 hover:text-indigo-600`}
                  onClick={() => onChangeCity(city)}
                >
                  {city.label}
                </TextButton>
              );
            })}
          </GridCol>
        </Column>
      </AlertDialog>
    </>
  );
}

export default WFilterCareer;
