import React, { useEffect, useState } from "react";
import ItemCommentDetailRecognition from "./item/item_comment_recognition";
import { Column, HorizontalDivider, Title } from "../../shared/ui";
import { getFullNameUser } from "../../controllers/apicontrollers";
import { useLocation } from "react-router-dom";

function ListCommentDetailRecognition({ comments }) {
  const location = useLocation();
  const [commentWithNames, setCommentWithNames] = useState([]);

  const fetchUserNames = async () => {
    const updatedComments = await Promise.all(
      comments.map(async (comment) => {
        const result = await getFullNameUser(
          comment.id_identify,
          comment.id_user
        );
        return {
          ...comment,
          fullName: result.status ? result.data : "Tidak diketahui",
        };
      })
    );
    setCommentWithNames(updatedComments);
  };

  useEffect(() => {
    fetchUserNames();
  }, [comments]);

  const isBeritaPage = location.pathname.startsWith("/berita");
  const isAdminPage = location.pathname.startsWith("/admin");

  const displayedComments = isBeritaPage ? commentWithNames : comments;

  return (
    displayedComments.length > 0 && (
      <Column className="gap-y-5">
        <HorizontalDivider className="my-4" />
        <Title>Semua Komentar</Title>
        <Column className="gap-y-10">
          {displayedComments.map((comment, idx) => (
            <ItemCommentDetailRecognition
              key={comment.id || idx}
              comment={comment}
            />
          ))}
        </Column>
      </Column>
    )
  );
}

export default ListCommentDetailRecognition;
