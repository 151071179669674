import React, { useEffect, useState } from "react";
import CHistory from "./cards/chistory";
import { getHistoryConsultation } from "../controllers/apicontrollers";
import { Caption, Column } from "../shared/ui";

const WAlmHistoryConsultation = ({
  action,
  currentMessage,
  onSelectedMessage,
  updateMessage,
  reqUpdate,
}) => {
  const [listHistory, setListHistory] = useState([]);

  const getAllHistory = async () => {
    const result = await getHistoryConsultation(action);
    if (result.status) {
      setListHistory(result.data);
    }
  };

  const onClickMessage = (message) => {
    onSelectedMessage(message);
  };

  useEffect(() => {
    getAllHistory();
  }, [reqUpdate]);

  useEffect(() => {
    if (updateMessage !== null) {
      const newState = listHistory.map((history) => {
        if (history.id_konsultasi === updateMessage.id) {
          let update = history;
          update.last_chat = updateMessage.message;
          update.last_chat_time = updateMessage.date;
          return update;
        } else {
          return history;
        }
      });
      setListHistory(newState);
    }
  }, [updateMessage]);

  return (
    listHistory.length > 0 && (
      <Column className="p-2 gap-1.5">
        <Caption className="pl-1">
          {action === 10
            ? "Semua Konsultasi"
            : action === 20
            ? "Menunggu Persetujuan"
            : "Konsultasi Ditolak"}
        </Caption>
        {listHistory.map((history, idx) => {
          return (
            <CHistory
              action={10}
              key={idx}
              history={history}
              currentId={currentMessage.id_konsultasi}
              onClick={onClickMessage}
            />
          );
        })}
      </Column>
    )
  );
};

export default WAlmHistoryConsultation;
